import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PropTypes from "prop-types";
import TeacherClass from "./TeacherClass";
import CustomSeparator from "components/Breadcrumb";
import TeacherLessonOHistory from "./TeacherLessonOHistory";
import TeachersReport from "./TeachersReport";
import HomeIcon from "@mui/icons-material/Home";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ApiService2 from "services/apiService2";
import CommonService from "services/commonService";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TeacherPDP from "./TeacherPDPlist";
import APPCONSTANTS from "configs/constants";
import axios from "axios";
import IconLogo from "components/IconLogo";
import { setSubjectHistory } from "state";
import Index from "./Attainment&progress/Index";

const API_BASE_URL = APPCONSTANTS.API_BASE_URL; // Replace with your API's base URL
const customHeaders = {
  "Content-Type": "application/json", // You can add other headers as needed
  "X-Auth-Token": APPCONSTANTS.X_AUTH_TOKEN,
};
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: customHeaders,
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { xs: 0, md: 3 }, mt: { xs: 1, md: 0 } }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 2,
  p: 3,
  height: 550,
};

const Mobstyle = {
  width: "100%",
  bgcolor: "background.paper",
  p: 1,
  height: "100%",
};

const TeacherView = () => {
  const SubjectIds = useSelector((state) => state.commonSubjectIds);
  const kgphase = useSelector((state) => state.kgPhase);
  const isNonMobleScreen = useMediaQuery("(min-width:900px)");
  const teacher = useSelector((state) => state.teacher);
  const userData = useSelector((state) => state.user);
  let url_params = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRole = localStorage.getItem("Role");
  const [FacId, setFacId] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [compareData, setCompareData] = useState("");
  const [teacherSubject, setTeacherSubject] = useState([]);
  const [selectSubject, setSelectSubject] = useState("");
  const [teacherPDPsubject, setTeacherPDPsubject] = useState([]);

  useEffect(() => {
    getTeacherSubject();
  }, [url_params.teacher_id]);

  const getTeacherSubject = async () => {
    try {
      let req = {
        school_id: userData.school_id,
        academic_year: userData.academic_year,
        teacher_id: url_params.teacher_id,
        user_id: userData.user_id,
      };
      let apiResult = await axiosInstance.post(
        "csdashboard/getSubjectOfTeacher",
        req
      );
      let data = apiResult.data.data;
      let orderd = orderSubject(data.details.subjects);
      let pdpodered = orderSubject(data.details.pdp_subjects);
      setTeacherSubject(orderd);
      setTeacherPDPsubject(pdpodered);
      if (sss.get("search") && sss.get("search") == "true") {
        if (orderd && orderd.length) {
          setSelectSubject(orderd[0]._id);
        }
      } else {
        setSelectSubject(url_params.subject);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sss = new URLSearchParams(window.location.search);
  let tab = 0;
  if (sss.get("tab") && sss.get("tab") == "obser") {
    tab = 1;
  }

  let idd =
    SubjectIds &&
    SubjectIds.some(
      (item) => /*selectSubject ? selectSubject :*/ url_params.subject === item
    );

  console.log(idd);

  const [value, setValue] = useState(tab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (!idd) {
      if (newValue == 1) {
        toast.success("Personal Development Plan is Selected");
      } else {
        toast.success("Lesson Observation History is Selected");
      }
    } else {
      if (newValue == 1) {
        toast.success("Lesson Observation History is Selected");
      } else if (newValue == 2) {
        toast.success("Teacher Report is Selected");
      } else if (newValue == 3) {
        toast.success("Attainment & Progress is Selected");
        // toast.success("Personal Development Plan is Selected");
        // if (!teacherPDPsubIDs.includes(selectSubject)) {
        //   setSelectSubject(
        //     teacherPDPsubject && teacherPDPsubject.length > 0
        //       ? teacherPDPsubject[0]._id
        //       : teacherPDPsubject.length == 0
        //       ? prioritySub[0]._id
        //       : null
        //   );
        // }
      } else {
        toast.success("Teacher Class Story is Selected");
      }
    }
  };

  const uniqueArray = teacher.details
    ? teacher.details.filter(
        (element, index, self) =>
          index ===
          self.findIndex(
            (e) =>
              e.session === element.session &&
              e.curriculum === element.curriculum
          )
      )
    : "";

  const ddd = (valu, ic) => {
    if (ic) {
      setFacId((prevIds) => [...prevIds, valu]);
    } else {
      setFacId((prevIds) => prevIds.filter((prevId) => prevId !== valu));
    }
  };

  const hasDuplicate = (array) => {
    const uniqueIds = new Set(array);
    return uniqueIds.size !== array.length;
  };

  const loTeacherCompare = async () => {
    if (FacId.length < 2) {
      toast.error("You have to select atleast 2 teachers");
    } else if (hasDuplicate(FacId)) {
      toast.error(
        "You have selected same teacher select another teacher to continue"
      );
    } else if (FacId.length >= 6) {
      toast.error("You can only select upto 5 teachers to compare");
    } else {
      handleOpen();
      toast.success("Comparing Teachers");
      CommonService.loaderON(dispatch);
      try {
        const req = {
          school_id: userData.school_id,
          academic_year: userData.academic_year,
          user_id: userData.user_id,
          isInit: true,
          _selections: {
            eval_type: ["lesson_observation", "learning_walk"],
            Faculties: FacId,
          },
        };
        const apiResult = await ApiService2.post(
          "learning-observation/get/submissions/report",
          req
        );
        const data = apiResult;
        setCompareData(data.details);
        CommonService.loaderOFF(dispatch);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const getStarRating = (data) => {
    return (
      <div>
        {data.hasOwnProperty("outstanding") && (
          <div>⭐⭐⭐⭐⭐ ({data.outstanding.length})</div>
        )}
        {data.hasOwnProperty("very_good") && (
          <div>⭐⭐⭐⭐ ({data.very_good.length})</div>
        )}
        {data.hasOwnProperty("good") && <div>⭐⭐⭐ ({data.good.length})</div>}
        {data.hasOwnProperty("acceptable") && (
          <div>⭐⭐ ({data.acceptable.length})</div>
        )}
        {data.hasOwnProperty("weak") && <div>⭐ ({data.weak.length})</div>}
      </div>
    );
  };

  const teacherPDPsubIDs = teacherPDPsubject.map((data) => data._id);

  const handleSubjectChange = async (e) => {
    setSelectSubject(e.target.value);
    if (!SubjectIds.includes(e.target.value)) {
      setValue(1);
    } else {
      setValue(3);
    }
  };

  const handleClassSubjectChange = async (e) => {
    setSelectSubject(e.target.value);
  };

  const prioritySub = teacherSubject.filter((data) =>
    SubjectIds.includes(data._id)
  );

  let priorityLanguages = [
    "English",
    "Science",
    "Mathematics",
    "Environmental Studies",
    "Physics",
    "Chemistry",
    "Biology",
  ];

  const orderSubject = (array) => {
    array.sort((a, b) => {
      let nameA = a.name;
      let nameB = b.name;

      let indexA = priorityLanguages.indexOf(nameA);
      let indexB = priorityLanguages.indexOf(nameB);

      if (indexA === -1)
        indexA =
          priorityLanguages.length +
          teacherSubject.findIndex((lang) => lang.name === nameA);
      if (indexB === -1)
        indexB =
          priorityLanguages.length +
          teacherSubject.findIndex((lang) => lang.name === nameB);

      return indexA - indexB;
    });
    return array;
  };

  return (
    <Box padding={{ xs: 0, md: 2 }} ml={{ xs: 0, md: 3, lg: 1 }} mt={{ xs: 1 }}>
      <Box ml={2} display={{ xs: "none", md: "flex" }}>
        <CustomSeparator
          titles={[
            {
              title: "Home",
              icon: "",
              link: true,
              path: "/",
            },
            {
              title: "Dashboard",
              icon: "",
              link: true,
              path: "/",
            },
            {
              title: "Class Story",
              icon: "",
              link: false,
              path: "/",
            },
          ]}
        />
      </Box>

      <Card
        variant="outlined"
        sx={{ borderRadius: { xs: 0, md: "20px !important" } }}
      >
        <CardContent>
          <Box display={"flex"}>
            <Button
              variant="text"
              onClick={() => {
                navigate("/home");
              }}
            >
              <ArrowBackIcon
                sx={
                  theme.palette.mode === "dark"
                    ? { color: "white" }
                    : { color: "black" }
                }
              />
            </Button>
            <Typography variant="h4" fontWeight={600} p={2}>
              {value == 4
                ? "Personal Development Plan"
                : kgphase != "KG" && !idd && value == 1
                ? "Personal Development Plan"
                : "User Class Stories"}
            </Typography>
          </Box>

          <Divider />

          <Box p={{ xs: 2, md: 4 }}>
            <Box display={"flex"} sx={{ gap: 2 }}>
              <Avatar
                src={
                  teacher.image && teacher.image.original
                    ? APPCONSTANTS.FILE_SERVER_URL +
                      "/" +
                      teacher.image.original
                    : teacher.image
                }
                sx={{ height: 80, width: 80, borderRadius: "11.2px" }}
              ></Avatar>
              <Box>
                <Typography variant="body1">
                  <PeopleAltOutlinedIcon
                    sx={
                      theme.palette.mode === "dark"
                        ? {
                            color: "#fff",
                            transform: "translate(0px,2px)",
                          }
                        : {
                            color: "#292D32",
                            transform: "translate(0px,4px)",
                          }
                    }
                  />{" "}
                  {teacher.empNo || teacher.employee_no}
                </Typography>
                <Typography variant="body1" fontWeight={600}>
                  {teacher.name}
                </Typography>
                {uniqueArray
                  ? uniqueArray.map((data, index) => {
                      return data.session && data.curriculum ? (
                        <Chip
                          key={index}
                          size="small"
                          variant="filled"
                          label={`${data.session} / ${data.curriculum}`}
                          sx={{
                            borderRadius: "0",
                            backgroundColor: "#0A57EA1A",
                            color: "#0A57EA",
                          }}
                        />
                      ) : null;
                    })
                  : ""}
              </Box>
              {/* {(!idd && value === 1) || (idd && value === 3) ? (
                <Box ml={"auto"}>
                  {teacherPDPsubject &&
                    teacherPDPsubject.length > 0 && ( // Check if teacherPDPsubject is not empty
                      <FormControl fullWidth sx={{ minWidth: 200 }}>
                        <Typography>Please Select the Subject.</Typography>
                        <Select
                          labelId="subject-label"
                          id="subject"
                          onChange={handleSubjectChange}
                          value={selectSubject}
                        >
                          {teacherPDPsubject.map((item, index) => (
                            <MenuItem key={index} value={item._id}>
                              <Box display={"flex"}>
                                {item.name === "English" && (
                                  <IconLogo
                                    size="20"
                                    image="/assets/Book.png"
                                  />
                                )}
                                {item.name === "Mathematics" && (
                                  <IconLogo
                                    size="20"
                                    image="/assets/maths.png"
                                  />
                                )}
                                {item.name === "Science" && (
                                  <IconLogo
                                    size="20"
                                    image="/assets/science.png"
                                  />
                                )}
                                <Typography
                                  sx={{
                                    paddingLeft: 1,
                                  }}
                                >
                                  {item.name}
                                </Typography>
                              </Box>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                </Box>
              ) : null} */}

              {/* {idd && value === 0 ? (
                <Box ml={"auto"}>
                  <FormControl fullWidth sx={{ minWidth: 200 }}>
                    <Select
                      labelId="subject-label"
                      id="subject"
                      onChange={handleClassSubjectChange}
                      value={selectSubject}
                    >
                      {prioritySub &&
                        prioritySub.map((item, index) => (
                          <MenuItem key={index} value={item._id}>
                            <Box display={"flex"}>
                              {item.name === "English" && (
                                <IconLogo size="20" image="/assets/Book.png" />
                              )}
                              {item.name === "Mathematics" && (
                                <IconLogo size="20" image="/assets/maths.png" />
                              )}
                              {item.name === "Science" && (
                                <IconLogo
                                  size="20"
                                  image="/assets/science.png"
                                />
                              )}
                              <Typography
                                sx={{
                                  paddingLeft: 1,
                                }}
                              >
                                {item.name}
                              </Typography>
                            </Box>
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              ) : null} */}
            </Box>
          </Box>

          <Box
            display={{ xs: "block", md: "flex" }}
            sx={{ textAlign: "center" }}
          >
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                width: "100%",
              }}
            >
              <Tabs
                sx={{
                  pl: { xs: 0, md: 2 },
                }}
                value={value}
                onChange={handleChange}
                variant={isNonMobleScreen ? "standard" : "fullWidth"}
                aria-label="basic tabs example"
              >
                {kgphase != "KG" && idd ? (
                  <Tab
                    sx={{
                      fontSize: { xs: 9, md: 12 },
                      textTransform: "capitalize",
                      minWidth: 75,
                    }}
                    label="Teacher Class Story"
                    {...a11yProps(0)}
                  />
                ) : (
                  ""
                )}
                <Tab
                  sx={{
                    fontSize: { xs: 9, md: 12 },
                    textTransform: "capitalize",
                    minWidth: 75,
                  }}
                  label="Lesson Observation History"
                  {...a11yProps(
                    kgphase == "KG" ? 0 : kgphase != "KG" && !idd ? 0 : 1
                  )}
                />
                {kgphase != "KG" && idd ? (
                  <Tab
                    sx={{
                      fontSize: { xs: 9, md: 12 },
                      textTransform: "capitalize",
                      minWidth: 75,
                    }}
                    label="Teacher Reports"
                    {...a11yProps(2)}
                  />
                ) : (
                  ""
                )}
                {/* <Tab
                  sx={{
                    fontSize: { xs: 9, md: 12 },
                    textTransform: "capitalize",
                    minWidth: 75,
                  }}
                  label="PDP"
                  {...a11yProps(
                    kgphase == "KG" ? 1 : kgphase != "KG" && !idd ? 1 : 3
                  )}
                /> */}
                {kgphase != "KG" && idd ? (
                  <Tab
                    sx={{
                      fontSize: { xs: 9, md: 12 },
                      textTransform: "capitalize",
                      minWidth: 75,
                    }}
                    label="Attainment & Progress"
                    {...a11yProps(3)}
                  />
                ) : (
                  ""
                )}
              </Tabs>
            </Box>
            {/* {(userRole == "HOD" || userRole == "HOS") && value == 1 ? (
              <Button
                size="small"
                variant="contained"
                sx={{
                  ml: "auto",
                  mt: { xs: 2, md: 0 },
                  width: { xs: "auto", lg: "20%" },
                  color: "white",
                  backgroundColor: "#3699FF",
                  mr: { xs: 0, lg: 3 },
                }}
                onClick={loTeacherCompare}
              >
                <FileCopyIcon /> Compare Teacher
              </Button>
            ) : (
              ""
            )} */}
          </Box>
          <Box>
            {kgphase != "KG" && idd ? (
              <CustomTabPanel value={value} index={0}>
                <TeacherClass selectSubject={selectSubject} />
              </CustomTabPanel>
            ) : (
              ""
            )}
            <CustomTabPanel
              value={value}
              index={kgphase == "KG" ? 0 : kgphase != "KG" && !idd ? 0 : 1}
            >
              <TeacherLessonOHistory
                teacherId={url_params.teacher_id}
                setFacId={setFacId}
                FacId={FacId}
                ddd={ddd}
                subId={url_params.subject}
              />
            </CustomTabPanel>
            {kgphase != "KG" && idd ? (
              <CustomTabPanel value={value} index={2}>
                <TeachersReport teacherId={url_params.teacher_id} />
              </CustomTabPanel>
            ) : (
              ""
            )}
            {/* <CustomTabPanel
              value={value}
              index={kgphase == "KG" ? 1 : kgphase != "KG" && !idd ? 1 : 3}
            >
              <TeacherPDP selectSubject={selectSubject} />
            </CustomTabPanel> */}

            {kgphase != "KG" && idd ? (
              <CustomTabPanel value={value} index={3}>
                <Index />
              </CustomTabPanel>
            ) : (
              ""
            )}
          </Box>
        </CardContent>
      </Card>
      <Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={isNonMobleScreen ? style : Mobstyle}>
            <IconButton
              sx={{
                bgcolor: "rgb(40,53,147)",
                display: "flex",
                ml: "auto",
                ":hover": {
                  bgcolor: "rgb(40,53,147)",
                },
              }}
            >
              <PictureAsPdfIcon sx={{ color: "#fff" }} />
            </IconButton>
            <Box display={"flex"} justifyContent={"center"}>
              <IconButton
                onClick={handleClose}
                sx={{ display: { xs: "flex", md: "none" } }}
              >
                <ArrowBackIcon sx={{ color: "#000" }} />
              </IconButton>
              <Typography
                id="modal-modal-title"
                variant="h4"
                fontWeight={600}
                textAlign={"center"}
                justifyContent={"center"}
              >
                Teacher Comparison Report
              </Typography>
            </Box>

            <TableContainer sx={{ p: 2, mt: 1 }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        height: 100,
                        border: "1px solid #e9ecef",
                        bgcolor: "#1d3557",
                        color: "#fff",
                        fontSize: 16,
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      Faculty
                    </TableCell>
                    {compareData && compareData.teachers
                      ? compareData.teachers.map((data, index) => {
                          return (
                            <TableCell
                              key={index}
                              sx={{
                                height: 100,
                                border: "1px solid #e9ecef",
                                fontSize: 15,
                                fontWeight: 500,
                                textAlign: "center",
                              }}
                            >
                              {data}
                            </TableCell>
                          );
                        })
                      : ""}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        height: 100,
                        border: "1px solid #e9ecef",
                        bgcolor: "#1d3557",
                        color: "#fff",
                        fontSize: 16,
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      Role
                    </TableCell>
                    {compareData && compareData.rolesTeacher
                      ? compareData.rolesTeacher.map((data, index) => {
                          return (
                            <TableCell
                              key={index}
                              sx={{
                                height: 100,
                                border: "1px solid #e9ecef",
                                fontSize: 15,
                                fontWeight: 500,
                                textAlign: "center",
                              }}
                            >
                              {data + " "}
                            </TableCell>
                          );
                        })
                      : ""}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        height: 100,
                        border: "1px solid #e9ecef",
                        bgcolor: "#1d3557",
                        color: "#fff",
                        fontSize: 16,
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      LO
                    </TableCell>
                    {compareData && compareData.LoValuesTeacher
                      ? compareData.LoValuesTeacher.map((data, index) => {
                          return (
                            <TableCell
                              key={index}
                              sx={{
                                height: 100,
                                border: "1px solid #e9ecef",
                                textAlign: "center",
                              }}
                            >
                              {getStarRating(data)}
                            </TableCell>
                          );
                        })
                      : ""}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        height: 100,
                        border: "1px solid #e9ecef",
                        bgcolor: "#1d3557",
                        color: "#fff",
                        fontSize: 16,
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      LW
                    </TableCell>
                    {compareData && compareData.LwValuesTeacher
                      ? compareData.LwValuesTeacher.map((data, index) => {
                          return (
                            <TableCell
                              key={index}
                              sx={{
                                height: 100,
                                border: "1px solid #e9ecef",
                                textAlign: "center",
                              }}
                            >
                              {getStarRating(data)}
                            </TableCell>
                          );
                        })
                      : ""}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default TeacherView;
