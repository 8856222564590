import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Checkbox,
  Chip,
  Drawer,
  FormControlLabel,
  IconButton,
  Modal,
  Pagination,
  TableHead,
  Tooltip,
  Typography,
  TablePagination,
  useMediaQuery,
} from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import StarIcon from "@mui/icons-material/Star";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TeacherWalkView from "./TeacherWalkView";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TeacherLessonObservation from "./TeacherLessonObservation";
import axios from "axios";
import { useEffect, useState } from "react";
import ApiService from "services/apiService";
import ApiService2 from "services/apiService2";
import { useSelector } from "react-redux";
import moment from "moment";
import CommonService from "services/commonService";
import { useDispatch } from "react-redux";
import IconLogo from "components/IconLogo";
import { toast } from "react-toastify";
import { logDOM } from "@testing-library/react";

const TeacherLessonOHistory = ({ teacherId, setFacId, FacId, ddd, subId }) => {
  const isNonMobileScreen = useMediaQuery("(min-width:900px)");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [open, setOpen] = useState(false);
  const [openLessonOb, setOpenLessonOb] = useState(false);
  const handleClose = () => setOpen(false);
  const [allChecked, setAllChecked] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [ids, setChecked] = useState([]);
  const userData = useSelector((state) => state.user);
  const [loHistory, setloHistory] = useState([]);
  const [obHistory, setObHistory] = useState([]);
  const [obDates, setObDates] = useState();
  const dispatch = useDispatch();
  const [Cindicator, setIndicator] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const pageCount = Math.ceil(totalCount / rowsPerPage);
  const startIndex = page * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage - 1, totalCount - 1);

  const handleOpen = (id, teacherId) => {
    setOpen(true);
    setSelectedId(id);
  };

  const handleGraphOpen = async (id, teacherId, data) => {
    setSelectedId(id);
    setIndicator(data);
    await GetObGraph(teacherId, data.type);
  };

  const GetObGraph = async (teacherId, type) => {
    try {
      let req = {
        school_id: userData.school_id,
        academic_year: userData.academic_year,
        teacher_id: teacherId,
        FILE_UPLOAD_URL: "https://teamsqa4000.educore.guru",
        eval_type: type ? type : Cindicator.type,
      };
      CommonService.loaderON(dispatch);
      let apiResult = await ApiService2.post(
        "learning-observation/analysis",
        req
      );
      CommonService.loaderOFF(dispatch);
      const data = apiResult.details;
      let arr = [];
      data.dates.map((rw) => {
        if (rw.evaluation) {
          arr.push(Math.round(rw.evaluation.total_percentage));
        }
      });
      setObDates(arr);
      setObHistory(data);
      setOpenLessonOb(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    GetLoHistoryData();
    window.scrollTo(0, 0);
  }, [page]);

  const GetLoHistoryData = async () => {
    try {
      let req = {
        school_id: userData.school_id,
        academic_year: userData.academic_year,
        user_id: teacherId,
        subject_id: subId,
        page: page + 1,
        perPage: rowsPerPage,
        _selections: {
          eval_type: ["lesson_observation", "learning_walk"],
        },
      };
      CommonService.loaderON(dispatch);
      let apiResult = await ApiService.post("bmr/lohistory", req);
      const data = apiResult;
      setloHistory(data.details.response._list);
      setTotalCount(data.details.response.total_count);
      CommonService.loaderOFF(dispatch);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalCount) : 0;

  const handleSelectAllChange = (isChecked) => {
    let updatedList = [];
    if (isChecked) {
      setAllChecked(true);
      for (let index = 0; index < totalCount; index++) {
        let element = loHistory[index];
        updatedList.push(element._id);
      }
    } else {
      setAllChecked(false);
    }
    setChecked(updatedList);
  };

  const handleCheckboxChange = (id, isChecked, row) => {
    ddd(row.teacher_id, isChecked);
    let updatedList = [...ids];
    if (isChecked) {
      updatedList = [...ids, id];
    } else {
      setAllChecked(false);
      updatedList.splice(ids.indexOf(id), 1);
    }
    setChecked(updatedList);
  };

  const GetObData = async () => {
    try {
      let req = {
        school_id: userData.school_id,
        academic_year: userData.academic_year,
        // teacher_id: "tgYaSh2wP74xiWGFA",
        teacher_id: teacherId,
        FILE_UPLOAD_URL: "https://teamsqa4000.educore.guru",
        eval_type: "learning_walk",
      };
      let apiResult = await ApiService.post(
        "learning-observation/analysis",
        req
      );
      const data = apiResult.details;
      let arr = [];
      data.dates.map((rw) => {
        if (rw.evaluation) {
          arr.push(Math.round(rw.evaluation.total_percentage));
        }
      });
      setObDates(arr);
      setObHistory(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
  };

  return (
    <Box>
      <TableContainer component={Paper} sx={{ boxShadow: "0px 0px 2px #000" }}>
        <Table sx={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              {/* <TableCell>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={"select_all"}
                      value={"select_all"}
                      checked={allChecked}
                      onChange={(e) => handleSelectAllChange(e.target.checked)}
                    />
                  }
                  label="Select All"
                />
              </TableCell> */}
              <TableCell sx={{ fontWeight: "600", width: 100 }} align="center">
                # Sl No
              </TableCell>
              <TableCell sx={{ fontWeight: "600" }}>Type</TableCell>
              <TableCell sx={{ fontWeight: "600" }}>Observer</TableCell>
              <TableCell sx={{ fontWeight: "600", width: 100 }}>
                Observer Role
              </TableCell>
              <TableCell sx={{ fontWeight: "600" }}>Class</TableCell>
              <TableCell sx={{ fontWeight: "600" }}>Subject</TableCell>
              <TableCell sx={{ fontWeight: "600" }}>Submitted Date</TableCell>
              <TableCell sx={{ fontWeight: "600" }}>Rating</TableCell>
              <TableCell sx={{ fontWeight: "600" }} colSpan={2}>
                View
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loHistory.map((row, index) => (
              <TableRow key={index}>
                {/* <TableCell>
                  <Checkbox
                    id={row._id}
                    name={row._id}
                    value={row._id}
                    checked={ids.includes(row._id) ? true : false}
                    onChange={(e) =>
                      handleCheckboxChange(row._id, e.target.checked,row)
                    }
                  />
                </TableCell> */}
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell>{row.type_name}</TableCell>
                <TableCell>{row.observer_name}</TableCell>
                <TableCell>
                  {row.observer_roles
                    .filter(
                      (data) => data.name != "Teacher" && data.name != "Parent"
                    )
                    .map((data) => data.name + " ")}
                </TableCell>
                <TableCell>{row.class_batch_name}</TableCell>
                <TableCell>{row.subject_name}</TableCell>
                <TableCell>
                  {moment(row.date_of_observation).utc().format("DD-MM-YYYY")}
                </TableCell>
                <TableCell align="right" width={180}>
                  {(() => {
                    const commonProps = {
                      borderRadius: "0",
                      backgroundColor: "",
                      color: "",
                    };

                    const getBoxContent = (gradeColor, label, rating) => (
                      <Box display={"flex"} gap={1}>
                        <Chip
                          size="small"
                          icon={
                            <FiberManualRecordIcon
                              fontSize="small"
                              style={{ color: gradeColor }}
                            />
                          }
                          sx={{
                            ...commonProps,
                            backgroundColor: `${gradeColor}1A`,
                            color: gradeColor,
                            textTransform: "capitalize",
                          }}
                          label={label}
                        />
                        <Typography>
                          {`(${rating}`}
                          <StarIcon
                            sx={{
                              transform: "translate(0px,2px)",
                              fontSize: "15px",
                              color: "#FFA500",
                            }}
                          />
                          {")"}
                        </Typography>
                      </Box>
                    );

                    if (row.updated_grade) {
                      switch (row.updated_grade) {
                        case "outstanding":
                          return getBoxContent(
                            "#0000FF",
                            row.updated_grade,
                            "5"
                          );
                        case "very good":
                          return getBoxContent(
                            "#008000",
                            row.updated_grade,
                            "4"
                          );
                        case "good":
                          return getBoxContent(
                            "#00A3FF",
                            row.updated_grade,
                            "3"
                          );
                        case "acceptable":
                          return getBoxContent(
                            "#FFA500",
                            row.updated_grade,
                            "2"
                          );
                        case "weak":
                          return getBoxContent(
                            "#FF0000",
                            row.updated_grade,
                            "1"
                          );
                        default:
                          return "";
                      }
                    } else if (row.evaluation?.total_grade) {
                      switch (row.evaluation.total_grade) {
                        case "outstanding":
                          return getBoxContent(
                            "#0000FF",
                            row.evaluation.total_grade,
                            "5"
                          );
                        case "very good":
                          return getBoxContent(
                            "#008000",
                            row.evaluation.total_grade,
                            "4"
                          );
                        case "good":
                          return getBoxContent(
                            "#00A3FF",
                            row.evaluation.total_grade,
                            "3"
                          );
                        case "acceptable":
                          return getBoxContent(
                            "#FFA500",
                            row.evaluation.total_grade,
                            "2"
                          );
                        case "weak":
                          return getBoxContent(
                            "#FF0000",
                            row.evaluation.total_grade,
                            "1"
                          );
                        default:
                          return "";
                      }
                    } else {
                      return "";
                    }
                  })()}
                </TableCell>

                <TableCell>
                  <Box sx={{ display: "flex" }}>
                    <Tooltip title={"Learning Walk View"}>
                      <IconButton
                        sx={{ color: "white" }}
                        onClick={() => handleOpen(row._id, row.teacher_id)}
                      >
                        <img
                          style={{ filter: "drop-shadow(0px 0px 3px #fff)" }}
                          src="/assets/eye.png"
                          alt=""
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Lesson Observation"}>
                      <IconButton
                        onClick={() => {
                          handleGraphOpen(row._id, row.teacher_id, row);
                        }}
                      >
                        <img
                          style={{ filter: "drop-shadow(0px 0px 3px #fff)" }}
                          src="/assets/activity.png"
                          alt=""
                        />
                      </IconButton>
                    </Tooltip>
                    {row.isJoin ? (
                      <Tooltip title={"Joint Observation"}>
                        <IconButton>
                          <InfoOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
            {/* {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
              </TableRow>
            )} */}
            {totalCount === 0 ? (
              <TableRow>
                <TableCell colSpan={11} align="center">
                  No Data to Show
                </TableCell>
              </TableRow>
            ) : (
              ""
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <br />

      {loHistory.length === 0 ? (
        ""
      ) : (
        <Box display={{ xs: "block", md: "flex" }} mt={2}>
          <Typography>
            Showing {startIndex + 1} - {endIndex + 1} Results out of
            {" " + totalCount} items
          </Typography>
          <Pagination
            sx={{ ml: "auto", mt: { xs: 2, md: 0 } }}
            count={pageCount}
            page={page + 1}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        </Box>
      )}
      {/* ------------------------------------------------- */}
      <Drawer
        anchor="right"
        sx={
          isNonMobileScreen
            ? {
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: "75%",
                  borderRadius: "20px",
                },
              }
            : {
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: "100%",
                },
              }
        }
        open={open}
      >
        <Box>
          <TeacherWalkView
            setOpen={setOpen}
            loId={selectedId}
            GetLoHistoryData={GetLoHistoryData}
          />
        </Box>
      </Drawer>
      {/* ------------------------------------------------- */}
      <Drawer
        anchor="right"
        open={openLessonOb}
        sx={
          isNonMobileScreen
            ? {
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: "80%",
                  borderRadius: "20px",
                },
              }
            : {
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: "100%",
                },
              }
        }
      >
        <Box>
          <TeacherLessonObservation
            setOpenLessonOb={setOpenLessonOb}
            teacherId={teacherId}
            ratingarr={obDates}
            graph={obHistory}
            Cindicator={Cindicator}
          />
        </Box>
      </Drawer>
    </Box>
  );
};

export default TeacherLessonOHistory;
