import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";


const InitialTable = (props) => {

  return (
    <Box>
      <Box p={2} sx={{ backgroundColor: "#3699FF1A" }}>
        Initial Target Prediction Attainment Percentages
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ border: "1px solid #EEEEF2", width: 150,height:75 }}>
                Class
              </TableCell>
              <TableCell sx={{ border: "1px solid #EEEEF2",height:75 }}>
                % of students targeted with B1 and Above
              </TableCell>
              <TableCell sx={{ border: "1px solid #EEEEF2",height:75 }}>
                % of students targeted with C1 and Above
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.stories &&
              props.stories.map((data, index) => {
                var arr = data.academic.split("-");
                return (
                  <TableRow key={index}>
                    <TableCell sx={{ border: "1px solid #EEEEF2" }}>
                      {arr[0] + " " + arr[1]}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid #EEEEF2" }}>
                      {data.aboveB1per}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid #EEEEF2" }}>
                      {data.aboveC1per}
                    </TableCell>
                  </TableRow>
                );
              })}
            {props.stories.length === 0 ? (
              <TableRow>
                <TableCell
                  sx={{ border: "1px solid #EEEEF2" }}
                  align="center"
                  colSpan={3}
                >
                  No Data to Show
                </TableCell>
              </TableRow>
            ) : (
              ""
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default InitialTable;
