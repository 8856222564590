import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import ApiService from "services/apiService2";
import CommonService from "services/commonService";
import { useDispatch } from "react-redux";
import InitialTable from "./InitialTable";
import Targettable from "./Targettable";
import { Grid, Typography } from "@mui/material";

const TeacherClass = ({ selectSubject }) => {
  const [page, setPage] = useState(0);
  const [stories, setStories] = useState([]);
  const [targetData, setTargetData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const classArr = useSelector((state) => state.classes);
  let url_params = useParams();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);

  useEffect(() => {
    fetchData();
    TargettableData();
    window.scrollTo(0, 0);
  }, [selectSubject]);

  const fetchData = async () => {
    CommonService.loaderON(dispatch);
    try {
      let req = {
        subject_id: selectSubject ? selectSubject : url_params.subject,
        activeAcd: "2023-2024",
        school_id: userData.school_id,
        user_id: url_params.teacher_id,
      };
      let apiResult = await ApiService.post("get/percentage/grade", req);
      const data = apiResult.details.response;
      data.sort(customSort);
      setStories(data);
      CommonService.loaderOFF(dispatch);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const TargettableData = async () => {
    CommonService.loaderON(dispatch);
    try {
      let req = {
        subject_id: selectSubject ? selectSubject : url_params.subject,
        academic_year: "2023-2024",
        school_id: userData.school_id,
        user_id: url_params.teacher_id,
      };
      let apiResult = await ApiService.post(
        "get/getAccuracyper/perc",
        // "set/tarGrdPredictionAccuracy",
        req
      );
      const data = apiResult.details.response;
      await data.sort(Targetsorter);
      // console.log(data, 109);
      setTargetData(data);
      CommonService.loaderOFF(dispatch);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const customSort = (a, b) => {
    const [numA, charA] = a ? a.academic.split("-") : "";
    const [numB, charB] = b ? b.academic.split("-") : "";

    if (numA === numB) {
      return charA.localeCompare(charB);
    }

    return parseInt(numA) - parseInt(numB);
  };

  function Targetsorter(a, b) {
    if (parseInt(a.class) < parseInt(b.class)) return -1;
    if (parseInt(a.class) > parseInt(b.class)) return 1;

    if (a.batch < b.batch) return -1;
    if (a.batch > b.batch) return 1;

    return 0;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <InitialTable stories={stories} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Targettable targetData={targetData} />
        </Grid>
      </Grid>
      <Box sx={{ bgcolor: "#00a6fb", width: "fit-content", px: 1 }}>
        <Typography sx={{ fontFamily: "serif", color: "#fff", mt: 5 }}>
          <span style={{ color: "red" }}>*</span> Note : Data refresh may take
          some time. Please be patient while the system updates the information.
        </Typography>
        <Typography sx={{ fontFamily: "serif", color: "#fff",pl:"12px" }}>
          If the half-yearly and final-year data for the current year are not
          yet completed, the data shown here for the students of these classes
          are from the previous year
        </Typography>
      </Box>
    </Box>
  );
};

export default TeacherClass;
