import { useSelector } from "react-redux";
import HomePage from "scenes/dashboard/";
import LoginPage from "scenes/loginPage";
import { Navigate, Routes, Route } from "react-router-dom";
import NormalLayout from "layouts/NormalLayout";
import NonAuthLayout from "layouts/NonAuthLayout";
import { AuthProtected } from "./AuthProtected";
import NotFound from "components/NotFound";
import React, { Suspense, lazy } from "react";
import MiniDrawer from "../sidebar/Sidebar";
import { Box, useMediaQuery } from "@mui/material";
import DuplicateWindow from "components/DuplicateWindow";
import TeacherView from "scenes/dashboard/TeacherView";
import LoginWithEmail from "components/LoginWithEmail";

const AppRoutes = () => {
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");
  const authProtectedRoutes = [
    { path: "/home", component: <HomePage /> },
    {
      path: "/teacher/view/:subject/:teacher_id",
      component: <TeacherView />,
    },
  ];
  const publicRoutes = [{ path: "/", component: <LoginPage /> }];
  const isAuth = Boolean(useSelector((state) => state.token));
  const toggleDrawer = () => {};
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/duplicate" element={<DuplicateWindow />} />
      <Route path="/login/:email" element={<LoginWithEmail />} />

      <Route
        path="/"
        element={
          isAuth ? (
            <AuthProtected>
              <Box>
                {isNonMobileScreens ? (
                  <Box sx={{ width: "6%" }}>
                    <MiniDrawer toggleDrawer={toggleDrawer} />
                  </Box>
                ) : (
                  ""
                )}

                <main
                  style={{
                    float: "right",
                    width: isNonMobileScreens ? "94%" : "100%",
                  }}
                >
                  <NormalLayout />
                  <HomePage />
                </main>
              </Box>
            </AuthProtected>
          ) : (
            <NonAuthLayout>
              <LoginPage />
            </NonAuthLayout>
          )
        }
      />
      {authProtectedRoutes.map((route, idx) => (
        <Route
          path={route.path}
          element={
            <AuthProtected>
              <Suspense>
                <Box width={"100%"}>
                  {isNonMobileScreens ? (
                    <Box sx={{ width: "6%" }}>
                      <MiniDrawer toggleDrawer={toggleDrawer} />
                    </Box>
                  ) : (
                    ""
                  )}
                  <main
                    style={{
                      float: "right",
                      width: isNonMobileScreens ? "94%" : "100%",
                    }}
                  >
                    <NormalLayout />
                    {route.component}
                  </main>
                </Box>
              </Suspense>
            </AuthProtected>
          }
          key={idx}
          exact
        />
      ))}
    </Routes>
  );
};

export default AppRoutes;
