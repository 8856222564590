import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import AttainmentTable from "./AttainmentTable";
import ProgressTable from "./ProgressTable";
import CommonService from "services/commonService";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "services/apiService2";
import { useParams } from "react-router-dom";

function Index() {
  const userData = useSelector((state) => state.user);
  let url_params = useParams();
  const dispatch = useDispatch();

  const [progressData, setProgressData] = useState([]);

  useEffect(() => {
    handleAttainmentData();
  }, []);

  const handleAttainmentData = async () => {
    CommonService.loaderON(dispatch);
    try {
      let req = {
        subject_id: url_params.subject,
        academic_year: userData.academic_year,
        school_id: userData.school_id,
        user_id: url_params.teacher_id,
        isAttainment : true
      };
      let apiResult = await ApiService.post(
        "/get/getAccuracyper/perc/attainment",
        req
      );
      const data = apiResult.details.response;
      await data.sort(Targetsorter);
      // console.log(data, 109);
      setProgressData(data);
      CommonService.loaderOFF(dispatch);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function Targetsorter(a, b) {
    if (parseInt(a.class) < parseInt(b.class)) return -1;
    if (parseInt(a.class) > parseInt(b.class)) return 1;

    if (a.batch < b.batch) return -1;
    if (a.batch > b.batch) return 1;

    return 0;
  }
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ProgressTable progressData={progressData} />
        </Grid>
        <Grid item xs={12} md={6}>
          <AttainmentTable progressData={progressData} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Index;
