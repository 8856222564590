import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Avatar,
  Button,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TableHead,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import ApiService from "services/apiService2";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import CommonService from "services/commonService";

const TeacherWalkView = ({ setOpen, loId, GetLoHistoryData }) => {
  const userData = useSelector((state) => state.user);
  const [viewData, setViewData] = useState([]);
  const [grade, setGrade] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    WalkViewData();
  }, []);

  const handleGradeChange = (e) => {
    toast.success(<div style={{textTransform:'capitalize'}}>{`${e.target.value + " is selected"}`}</div>);
    setGrade(e.target.value);
  };
  const WalkViewData = async () => {
    CommonService.loaderON(dispatch);
    try {
      let req = {
        _id: loId,
      };
      let apiResult = await ApiService.post(
        "lesson-observation/get/evaluation",
        req
      );
      const data = apiResult.details.response;
      setViewData(data);
      setGrade(
        data.updated_grade && data.updated_grade
          ? data.updated_grade
          : data.evaluation?.total_grade
      );
      CommonService.loaderOFF(dispatch);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    toast.success("Grade submitted successfully.");
    try {
      let req = {
        lo_id: loId,
        grade: grade,
      };
      let apiResult = await ApiService.post("update_grade", req);
      setOpen(false);
      GetLoHistoryData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Box height={"100vh"}>
      <Box>
        <Box p={{ xs: 2, md: 4 }} display={"flex"}>
          <Box>
            <Typography fontSize={24} fontWeight={600}>
              Learning Walk View
            </Typography>
          </Box>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
            sx={{ ml: "auto" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box display={{ sx: "block", md: "flex" }} sx={{ gap: 2 }} p={3}>
          <Box display={"flex"} gap={2}>
            <Avatar
              sx={{
                height: 70,
                width: 70,
                borderRadius: "0",
                color: "#00A3FF",
                fontWeight: 600,
              }}
            >
              {viewData.class_batch_name}
            </Avatar>
            <Box>
              <Typography fontSize={15}>
                <CalendarMonthIcon
                  sx={{
                    transform: "translate(0px,4px)",
                    fontSize: 15,
                  }}
                />
                {moment(viewData.date_of_observation).utc().format("L")}
              </Typography>
              <Typography variant="h6" fontWeight={600}>
                {viewData.teacher_name}
              </Typography>
              <Chip
                size="small"
                variant="filled"
                label={viewData.subject_name}
                sx={{
                  borderRadius: "0",
                  backgroundColor: "#3699FF",
                  color: "#fff",
                }}
              ></Chip>
            </Box>
          </Box>
          <Box sx={{ marginLeft: "auto", mt: { xs: 2, md: 0 } }}>
            <Typography fontSize={15} sx={{ color: "#787878" }}>
              Observer
            </Typography>
            <Typography fontSize={15}>
              <AccountBoxOutlinedIcon
                sx={{
                  fontSize: 18,
                  color: "#3699FF",
                  transform: "translate(0px,3px)",
                }}
              />
              {viewData.observer_name}
            </Typography>
            <Typography fontSize={15}>
              {viewData.topic ? (
                <MenuBookOutlinedIcon
                  sx={{
                    fontSize: 18,
                    color: "#3699FF",
                    transform: "translate(0px,3px)",
                  }}
                />
              ) : (
                ""
              )}
              {viewData.topic}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box p={{ xs: 2, md: 4 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "600",
                    fontSize: 12,
                    border: "1px solid #EEEEF2",
                    width: 80,
                  }}
                >
                  # Sl No
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "600",
                    fontSize: 12,
                    border: "1px solid #EEEEF2",
                  }}
                >
                  Lesson Observation Criteria
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "600",
                    fontSize: 12,
                    border: "1px solid #EEEEF2",
                  }}
                >
                  N/A
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "600",
                    fontSize: 12,
                    border: "1px solid #EEEEF2",
                  }}
                >
                  Weak
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "600",
                    fontSize: 12,
                    border: "1px solid #EEEEF2",
                  }}
                >
                  Acceptable
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "600",
                    fontSize: 12,
                    border: "1px solid #EEEEF2",
                  }}
                >
                  Good
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "600",
                    fontSize: 12,
                    border: "1px solid #EEEEF2",
                  }}
                >
                  Very Good
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "600",
                    fontSize: 12,
                    border: "1px solid #EEEEF2",
                  }}
                >
                  Outstanding
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {viewData.remarks_data ? (
                viewData.remarks_data[0].Indicators.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ border: "1px solid #EEEEF2" }}>
                      {index + 1}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid #EEEEF2" }}>
                      {row.name}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid #EEEEF2" }}>
                      {row.remark == "NA" ? (
                        <CheckCircleOutlinedIcon sx={{ color: "green" }} />
                      ) : (
                        ""
                      )}
                    </TableCell>

                    <TableCell sx={{ border: "1px solid #EEEEF2" }}>
                      {row.remark == "Weak" ? (
                        <CheckCircleOutlinedIcon sx={{ color: "green" }} />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid #EEEEF2" }}>
                      {row.remark == "Acceptable" ? (
                        <CheckCircleOutlinedIcon sx={{ color: "green" }} />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid #EEEEF2" }}>
                      {row.remark == "Good" ? (
                        <CheckCircleOutlinedIcon sx={{ color: "green" }} />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid #EEEEF2" }}>
                      {row.remark == "Very Good" ? (
                        <CheckCircleOutlinedIcon sx={{ color: "green" }} />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid #EEEEF2" }}>
                      {row.remark == "Outstanding" ? (
                        <CheckCircleOutlinedIcon sx={{ color: "green" }} />
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>No Data Found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid
          container
          spacing={2}
          width={{ xs: "100%", md: "90%" }}
          ml={{ xs: 2, md: 6 }}
          mt={4}
        >
          <Grid
            item
            xs={
              viewData.strengths && viewData.strengths[0].length >= 50
                ? 12
                : 6.1
            }
            display={{ xs: "block", md: "flex" }}
            justifyContent={"space-between"}
          >
            <Typography>Summary :</Typography>
            <TextareaAutosize
              mt={{ xs: 1, md: 0 }}
              style={
                viewData.strengths && viewData.strengths[0].length >= 50
                  ? { width: "700px" }
                  : { width: "250px" }
              }
              id="outlined-basic"
              variant="outlined"
              disabled
              value={viewData.strengths ? viewData.strengths[0] : ""}
            />
          </Grid>
          <Grid
            item
            xs={
              viewData.strengths && viewData.strengths[0].length >= 50
                ? 12
                : 6.1
            }
            display={{ xs: "block", md: "flex" }}
            justifyContent={"space-between"}
          >
            <Typography>What went well :</Typography>
            <TextareaAutosize
              mt={{ xs: 1, md: 0 }}
              size="small"
              id="outlined-basic"
              variant="outlined"
              style={
                viewData.strengths && viewData.strengths[0].length >= 50
                  ? { width: "700px" }
                  : { width: "250px" }
              }
              disabled
              value={
                viewData.areas_for_improvement
                  ? viewData.areas_for_improvement[0]
                  : ""
              }
            />
          </Grid>
          <Grid
            item
            xs={
              viewData.strengths && viewData.strengths[0].length >= 50
                ? 12
                : 6.1
            }
            display={{ xs: "block", md: "flex" }}
            justifyContent={"space-between"}
          >
            <Typography>Even better if :</Typography>
            <TextareaAutosize
              mt={{ xs: 1, md: 0 }}
              size="small"
              id="outlined-basic"
              variant="outlined"
              style={
                viewData.strengths && viewData.strengths[0].length >= 50
                  ? { width: "700px" }
                  : { width: "250px" }
              }
              disabled
              value={
                viewData.remedial_measures ? viewData.remedial_measures : ""
              }
            />
          </Grid>
          <Grid
            item
            xs={6}
            display={{ xs: "block", md: "flex" }}
            justifyContent={"space-between"}
            gap={2}
          >
            <Box
              size="small"
              variant="contained"
              sx={{
                mb: 1,
                width: "130px",
                bgcolor: "#3699FF",
                color: "#fff",
                borderRadius: 1,
                display: "flex",
                gap: 1,
                p: 0.8,
              }}
            >
              <img src="/assets/gradeLogo.png" alt="" height={25} width={25} />
              <Typography
                sx={{
                  textTransform: "capitalize",
                  mt: 0.2,
                }}
              >
                Grade
              </Typography>
            </Box>
            {":"}
            <FormControl sx={{ minWidth: 222 }} size="small">
              <Select
                id="outlined-basic"
                onChange={handleGradeChange}
                value={grade}
                disabled
              >
                <MenuItem
                  value="outstanding"
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  Outstanding
                </MenuItem>
                <MenuItem
                  value="very good"
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  Very good
                </MenuItem>
                <MenuItem
                  value="good"
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  Good
                </MenuItem>
                <MenuItem
                  value="acceptable"
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  Acceptable
                </MenuItem>
                <MenuItem
                  value="weak"
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  Weak
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box display={"flex"} gap={2} justifyContent={"center"} mt={3} pb={3}>
        <Button
          variant="outlined"
          onClick={handleClose}
          sx={{
            textTransform: "capitalize",
            color: "#6c757d",
            border: "1px solid #adb5bd",
            ":hover": {
              border: "1px solid #adb5bd",
            },
          }}
        >
          Cancel
        </Button>
        {/* <Button
          variant="contained"
          onClick={handleSave}
          sx={{
            textTransform: "capitalize",
            color: "#fff",
            bgcolor: "#3699FF",
            ":hover": { bgcolor: "#3699FF" },
          }}
        >
          Save Changes
        </Button> */}
      </Box>
    </Box>
  );
};

export default TeacherWalkView;
