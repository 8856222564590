import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/material";
import { useParams } from "react-router";
import CommonService from "services/commonService";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setLogin } from "state";
import APPCONSTANTS from "configs/constants";
import { useNavigate } from "react-router-dom";

const X_AUTH_TOKEN = APPCONSTANTS.X_AUTH_TOKEN;
const LoginWithEmail = () => {
  const [isLoading, setLoader] = useState(true);
  const [text, setText] = useState("");
  let url_params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  document.title = "CLASS STORY";

  useEffect(() => {
    CommonService.loaderON(dispatch);
    loginEmail();
    //const decryptedString = cryptr.decrypt(url_params.email);
  });

  const loginEmail = async () => {
    let req = {
      email: url_params.email,
    };
    const loggedInResponse = await fetch(
      APPCONSTANTS.LOGIN_API_BASE_URL + "v1/gsuit_login_auth",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": X_AUTH_TOKEN,
        },
        body: JSON.stringify(req),
      }
    );
    const loggedIn = await loggedInResponse.json();
    console.log(loggedIn);
    if (loggedIn.status) {
      if (loggedIn.status.code == 200) {
        toast.success("Logged in Successfully");
        _handleLoginSuccess(loggedIn.data.data);
      } else {
        /**Login Api error */
        toast.error("Cannot Login With The Credentials");
      }
    }
  };

  function _handleLoginSuccess(apiResult) {
    if (apiResult) {
      dispatch(
        setLogin({
          user: apiResult[0],
          token: apiResult[0].user_id,
        })
      );
      navigate("/home");
    }
  }

  return (
    <>
      {isLoading ? (
        <Box sx={{ width: "100%" }}>{/* <LinearProgress /> */}</Box>
      ) : (
        ""
      )}
    </>
  );
};

export default LoginWithEmail;
