import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const Targettable = (props) => {

  // console.log(props.targetData);
  return (
    <Box>
      <Box p={2} sx={{ backgroundColor: "#FFA5001A" }}>
        Target Grade Prediction Accuracy
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ border: "1px solid #EEEEF2",height:75 }}>Class</TableCell>
              <TableCell sx={{ border: "1px solid #EEEEF2",height:75 }}>
                Half Yearly
              </TableCell>
              <TableCell sx={{ border: "1px solid #EEEEF2",height:75 }}>
                Final Exam
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.targetData &&
              props.targetData.map((data, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell sx={{ border: "1px solid #EEEEF2" }}>{data.class + " " + data.batch}</TableCell>
                    <TableCell sx={{ border: "1px solid #EEEEF2" }}>{data.halfYearly}</TableCell>
                    <TableCell sx={{ border: "1px solid #EEEEF2" }}>{data.finalGrade}</TableCell>
                  </TableRow>
                );
              })}
            {props.targetData.length === 0 ? (
              <TableRow>
                <TableCell align="center" colSpan={3}>
                  No Data to Show
                </TableCell>
              </TableRow>
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Targettable;
