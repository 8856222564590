import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

function AttainmentTable({ progressData }) {
  const teacher = useSelector((state) => state.teacher);

  return (
    <Box>
      <Box p={2} sx={{ backgroundColor: "#FFA5001A" }}>
        Attainment Percentages
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ border: "1px solid #EEEEF2", width: 120, height: 75 }}
              >
                Teacher Name
              </TableCell>
              <TableCell
                sx={{ border: "1px solid #EEEEF2", width: 100, height: 75 }}
              >
                Class
              </TableCell>
              <TableCell sx={{ border: "1px solid #EEEEF2", height: 75 }}>
                % of students attained B1 and above
              </TableCell>
              <TableCell sx={{ border: "1px solid #EEEEF2", height: 75 }}>
                % of students attained C1 and above
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {progressData
              ? progressData.map((item, index) => (
                  <TableRow key={index}>
                    {index === 0 && (
                      <TableCell
                        rowSpan={progressData.length}
                        sx={{
                          border: "1px solid #EEEEF2",
                          verticalAlign: "top",
                          textAlign: "center",
                        }}
                      >
                        {teacher.name}
                      </TableCell>
                    )}
                    <TableCell sx={{ border: "1px solid #EEEEF2" }}>
                      {item.class + " " + item.batch}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid #EEEEF2" }}>
                      {item.aboveB1per}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid #EEEEF2" }}>
                      {item.aboveC1per}
                    </TableCell>
                  </TableRow>
                ))
              : ""}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default AttainmentTable;
