import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  useMediaQuery,
  Avatar,
  Chip,
  TextField,
  InputAdornment,
  Button,
  Link,
  ListItemIcon,
  useTheme,
  Tooltip,
} from "@mui/material";
import CustomSeparator from "components/Breadcrumb";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import ApiService from "services/apiService";
import CommonService from "services/commonService";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import TeacherList from "./TeacherList";
import IconLogo from "components/IconLogo";
import state, {
  setHistoryData,
  setPhaseHistoryData,
  setSubjectHistory,
  setSubjectIds,
} from "state";
import _ from "underscore";
const IndexPage = () => {
  const navigate = useNavigate();
  const isNonMobileScreens = useMediaQuery("(max-width: 900px)");
  const [isLoading, setLoader] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [classes, setClasses] = useState([]);
  const [subject, setSubject] = useState("");
  const [allSubjects, setAllSubjects] = useState([]);
  const [allHods, setAllHods] = useState([]);
  const [phase_id, setPhaseId] = useState("");
  const [phase, setPhase] = useState("");
  const [filteredTeachers, setFilteredTeachers] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [primaryRole, setPrimaryRole] = useState("");
  const [allPhases, getAllPhases] = useState([]);
  const userData = useSelector((state) => state.user);
  const [hod, setHod] = useState("");
  const dispatch = useDispatch();
  const HodData = useSelector((state) => state.hod_id);
  const SubjectData = useSelector((state) => state.subject_id);
  const phaseData = useSelector((state) => state.Hphases);
  const classesData = useSelector((state) => state.Hclasses);
  const phaseIDdata = useSelector((state) => state.phase_id);
  const theme = useTheme();

  const activeRole = localStorage.getItem("Role");

  useEffect(() => {
    setRole();
    const user = localStorage.getItem("authUser");
    dispatch(
      setSubjectIds({
        subjectIds: prioritySubjectIds,
        kgPhases: phase && phase == "KG" ? phase : "",
      })
    );
    if (user == "") {
      toast.error("Your not logged in.");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, []);

  const setRole = () => {
    const princi = userData.all_roles_array.find(
      (data) => data.name === "Principal"
    );
    const AcC = userData.all_roles_array.find(
      (data) => data.name === "Academic Co-ordinator"
    );
    const hos = userData.all_roles_array.find((data) => data.name === "HOS");
    const hod = userData.all_roles_array.find((data) => data.name === "HOD");
    if (AcC) {
      setPrimaryRole(AcC.name);
      fetchData();
      localStorage.setItem("Role", AcC.name);
    } else if (princi) {
      setPrimaryRole(princi.name);
      fetchData();
      localStorage.setItem("Role", princi.name);
    } else if (hos) {
      setPrimaryRole(hos.name);
      getHodHos(hos.name);
      localStorage.setItem("Role", hos.name);
    } else if (hod) {
      setPrimaryRole(hod.name);
      getHodHos(hod.name);
      localStorage.setItem("Role", hod.name);
    }
  };

  const getHodHos = async (role) => {
    CommonService.loaderON(dispatch);
    const roleP = role ? role : primaryRole;
    try {
      let req = {
        school_id: userData.school_id,
        user_id: userData.user_id,
        academic_year: userData.academic_year,
        role: activeRole,
      };
      let apiResult = await ApiService.post("bmr/details/hod/hos", req);
      const data = apiResult.details.response;
      if (roleP == "HOD") {
        if (data && data.hod_det && data.hod_det[0]) {
          await setItemsSubjectDrpDownUnique(data.hod_det);
          let classData = [];
          if (data.hod_det[0].academics.length) {
            for (
              let index = 0;
              index < data.hod_det[0].academics.length;
              index++
            ) {
              let element = data.hod_det[0].academics[index];
              for (let i = 0; i < element.data.length; i++) {
                classData.push(element.data[i].class._id);
              }
            }
          }
          setHod(userData.user_id);
          dispatch(
            setHistoryData({
              hod_id: userData.user_id,
            })
          );
          const uniqueArray = [...new Set(classData)];
          setClasses(uniqueArray);
          const initorder = orderSubject(data.hod_det);
          await handleTeachers(
            SubjectData ? SubjectData : initorder[0]._id,
            uniqueArray,
            userData.user_id
          );
        } else {
          setSubject("");
          setAllSubjects([]);
          setClasses([]);
          setAllHods([]);
          setHod("");
          setFilteredTeachers([]);
          dispatch(
            setHistoryData({
              hod_id: "",
            }),
            setSubjectHistory({
              subject_id: "",
            })
          );
        }
      } else {
        if (data && data.hos_det && data.hos_det[0]) {
          let classData = [];
          for (let index = 0; index < data.hos_det.length; index++) {
            classData = [...classData, ...data.hos_det[index].classes];
          }
          const uniqueArray = [...new Set(classData)];
          setClasses(uniqueArray);
          await handleSubjects(uniqueArray);
        }
      }
      CommonService.loaderOFF(dispatch);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    CommonService.loaderON(dispatch);
    try {
      let req = {
        school_id: userData.school_id,
        role: activeRole,
      };
      let apiResult = await ApiService.post("bmr/getPhases", req);
      const data = apiResult.details.phases;
      getAllPhases(data);
      const Phaseinit = data.find((data) => data.phase === "6 - 8 (Middle)");
      setPhase(phaseData ? phaseData : Phaseinit.phase);
      setPhaseId(phaseIDdata ? phaseIDdata :Phaseinit.phase_id);
      setClasses(classesData ? classesData : Phaseinit.classes);
      dispatch(
        setPhaseHistoryData({
          Hphases: phaseData ? phaseData : Phaseinit.phase,
          Hclasses: classesData ? classesData : Phaseinit.classes,
          phase_id: phaseIDdata ? phaseIDdata : Phaseinit.phase_id,
        })
      );
      CommonService.loaderOFF(dispatch);
      await handleSubjects(
        classesData ? classesData : Phaseinit.classes,
        phaseIDdata ? phaseIDdata : Phaseinit.phase_id
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePhaseChange = async (e) => {
    //CommonService.loaderON(dispatch);
    setPhase(e.target.value);
    toast.success(`You have selected ${e.target.value}`);
    const selectedPhase = allPhases.find((rep) => rep.phase === e.target.value);

    setPhaseId(selectedPhase.phase_id);
    try {
      let req = {
        school_id: userData.school_id,
        academic_year: userData.academic_year,
        class: selectedPhase.classes,
      };
      const apiResult = await ApiService.post("bmr/getPhaseSubjects", req);
      CommonService.loaderOFF(dispatch);
      const data = apiResult.details.response;
      if (data) {
        setClasses(selectedPhase.classes);
        dispatch(
          setPhaseHistoryData({
            Hphases: e.target.value,
            Hclasses: selectedPhase.classes,
            phase_id: selectedPhase.phase_id,
          })
        );
        setItemsSubjectDrpDownUnique(data);
        await handleSubjects(selectedPhase.classes, selectedPhase.phase_id);
      } else {
        setSubject("");
        setAllSubjects([]);
        setClasses([]);
        setAllHods([]);
        setHod("");
        setFilteredTeachers([]);
        dispatch(
          setHistoryData({
            hod_id: "",
          }),
          setSubjectHistory({
            subject_id: "",
          }),
          setPhaseHistoryData({
            Hphases: null,
            Hclasses: [],
            phase_id:""
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubjects = async (initClass = [], phaseId) => {
    CommonService.loaderON(dispatch);
    let classArr = initClass ? initClass : classes;
    try {
      let req = {
        class: classArr,
        school_id: userData.school_id,
        academic_year: userData.academic_year,
      };
      let apiResult = await ApiService.post("bmr/getPhaseSubjects", req);
      const data = apiResult.details.response;
      if (data && data[0]) {
        const subjectinit = data.find((data) => data._id === "subject123");
        await setItemsSubjectDrpDownUnique(data);
        // setSubject(SubjectData ? SubjectData : subjectinit._id);
        // dispatch(
        //   setSubjectHistory({
        //     subject_id: SubjectData ? SubjectData : subjectinit._id,
        //   })
        // );
        await handleHods(
          SubjectData ? SubjectData : subjectinit._id,
          classArr,
          phaseId,
          false
        );
      } else {
        setSubject("");
        setAllSubjects([]);
        setClasses([]);
        setAllHods([]);
        setHod("");
        setFilteredTeachers([]);
        dispatch(
          setHistoryData({
            hod_id: "",
          }),
          setSubjectHistory({
            subject_id: "",
          }),
          setPhaseHistoryData({
            Hclasses: [],
            phase_id:""
          })
        );
      }
      CommonService.loaderOFF(dispatch);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleHodChange = async (data) => {
    setHod(data._id);
    dispatch(
      setHistoryData({
        hod_id: data._id,
      })
    );
    toast.success(`You have selected ${data.name}`);
    await handleTeachers(subject, classes ? classes : classesData, data._id);
  };

  const handleSubjectChange = async (e) => {
    setSubject(e.target.value);
    dispatch(
      setSubjectHistory({
        subject_id: e.target.value,
      })
    );

    const subName = allSubjects.find((data) => data._id === e.target.value);
    toast.success(`You have selected ${subName.name}`);

    if (primaryRole == "HOD") {
      const selectedSub = allSubjects.find((rep) => rep._id === e.target.value);
      let classData = [];
      if (selectedSub) {
        for (let index = 0; index < selectedSub.academics.length; index++) {
          let element = selectedSub.academics[index];
          for (let i = 0; i < element.data.length; i++) {
            classData.push(element.data[i].class._id);
          }
        }
      }
      const uniqueArray = [...new Set(classData)];
      await handleTeachers(e.target.value, uniqueArray);
    } else {
      await handleHods(e.target.value, classes, phaseIDdata, true);
    }
  };

  const handleHods = async (sub, classArr, phaseId, fromSub = false) => {
    CommonService.loaderON(dispatch);
    try {
      let req = {
        role_id: "role121235",
        selected_phase: phaseId,
        school_id: userData.school_id,
        academic_year: userData.academic_year,
        subject_id: sub ? sub : subject,
      };
      let apiResult = await ApiService.post("bmr/getPhaseHod", req);
      const data = apiResult.details.response;
      const AscHod = data.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
      if (data && data[0]) {
        setAllHods(AscHod);
        if (fromSub) {
          setHod(data[0]._id);
          var hodId = data[0]._id;
        } else {
          setHod(HodData ? HodData : data[0]._id);
          var hodId = HodData ? HodData : data[0]._id;
        }

        dispatch(
          setHistoryData({
            hod_id: hodId,
          })
        );
        if (data.length) {
          await handleTeachers(sub ? sub : subject, classArr, hodId);
        }
      } else {
        setAllHods([]);
        setHod("");
        setFilteredTeachers([]);
        dispatch(
          setHistoryData({
            hod_id: "",
          })
        );
      }
      CommonService.loaderOFF(dispatch);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleTeachers = async (sub, classArr, HId) => {
    CommonService.loaderON(dispatch);
    try {
      let req = {
        role_id: "role12123rqwer",
        school_id: userData.school_id,
        academic_year: userData.academic_year,
        subject_id: sub ? sub : subject,
        classes: classArr ? classArr : classes,
        user_id: HId ? HId : HodData,
        // _selections: {
        //   eval_type: ["lesson_observation", "learning_walk"],
        // },
      };
      let apiResult = await ApiService.post("bmr/getPhaseTeacher", req);
      const data = apiResult.details.response;
      if (data) {
        const AscTeacher = data.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
        setTeachers(AscTeacher ?? []);
        setFilteredTeachers(data ?? []);
      } else {
        setFilteredTeachers([]);
        setTeachers([]);
      }
      CommonService.loaderOFF(dispatch);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);
    const filteredItems = teachers.filter((user) => {
      return user.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setFilteredTeachers(filteredItems);
    if (searchTerm == "") {
      setFilteredTeachers(teachers);
    }
  };

  let fixedOrder = [
    "KG",
    "1 - 5 (Primary)",
    "6 - 8 (Middle)",
    "9 - 10 (Secondary)",
    "11 - 12 (Higher Secondary)",
  ];

  fixedOrder.forEach((item, index) => {
    let currentIndex = allPhases.findIndex(
      (phaseObj) =>
        phaseObj.phase.trim().toLowerCase() === item.trim().toLowerCase()
    );
    if (currentIndex !== -1) {
      [allPhases[index], allPhases[currentIndex]] = [
        allPhases[currentIndex],
        allPhases[index],
      ];
    }
  });

  let prioritySubjectIds = [
    "subject123",
    "7658gfhNNBVVert",
    "765d8gfhNVVert",
    "WfhgjTGRkqY",
    "2134jectsdf123",
    "su8769MsEEWt1234",
    "poswetrYYs1234f",
  ];

  let priorityLanguages = [
    "English",
    "Science",
    "Mathematics",
    "Environmental Studies",
    "Physics",
    "Chemistry",
    "Biology",
  ];

  const orderSubject = (array) => {
    array.sort((a, b) => {
      let nameA = a.name;
      let nameB = b.name;

      let indexA = priorityLanguages.indexOf(nameA);
      let indexB = priorityLanguages.indexOf(nameB);

      if (indexA === -1)
        indexA =
          priorityLanguages.length +
          allSubjects.findIndex((lang) => lang.name === nameA);
      if (indexB === -1)
        indexB =
          priorityLanguages.length +
          allSubjects.findIndex((lang) => lang.name === nameB);

      return indexA - indexB;
    });
    return array;
  };

  async function setItemsSubjectDrpDownUnique(array = []) {
    if (array && array.length) {
      try {
        array = _.uniq(array, function (x) {
          return x._id;
        });
        const ordered = orderSubject(array);
        setAllSubjects(ordered);
        setSubject(SubjectData ? SubjectData : ordered[0]._id);
        dispatch(
          setSubjectHistory({
            subject_id: SubjectData ? SubjectData : ordered[0]._id,
          })
        );
      } catch (e1) {
        console.log(e1);
      }
    } else {
      setAllSubjects([]);
      setSubject("");
      dispatch(
        setSubjectHistory({
          subject_id: "",
        })
      );
    }
  }

  return (
    <>
      {isLoading ? (
        <Box sx={{ width: "100%" }}>{/* <LinearProgress /> */}</Box>
      ) : (
        ""
      )}
      <Box padding={{ xs: 0, md: 2 }} ml={{ xs: 0, md: 1 }} mt={{ xs: 1 }}>
        <Box ml={2} display={{ xs: "none", md: "flex" }}>
          <CustomSeparator
            titles={[
              {
                title: "Home",
                icon: "",
                link: true,
                path: "/",
              },
              {
                title: "Dashboard",
                icon: "",
                link: true,
                path: "/",
              },
            ]}
          />
        </Box>
        <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
          p={{ xs: 1, md: 0 }}
          pl={{ xs: 1, md: 2, lg: 0 }}
        >
          <Grid item xs={12}>
            <Card variant="outlined" sx={{ borderRadius: "20px !important" }}>
              <CardContent>
                <Typography variant="h4" fontWeight={600} p={2}>
                  {primaryRole} Dashboard
                </Typography>
                {/* <Link
                  href="http://localhost:4201/login/9ZokGcM2IqcMmVMTR3qpdAzpcstA7+7XwgfDN5B7eTA="
                  target="_blank"
                >
                  Login with Email
                </Link> */}
                <Grid container alignItems="stretch" spacing={3} p={1}>
                  {primaryRole == "Principal" ||
                  primaryRole == "Academic Co-ordinator" ? (
                    <Grid item xs={12} md={4} lg={3}>
                      <FormControl fullWidth>
                        <Typography>
                          Please Select your preferred Phase.
                        </Typography>
                        <Select
                          labelId="phase-label"
                          id="phase"
                          onChange={handlePhaseChange}
                          value={phase}
                          sx={{
                            background: "#3699FF",
                            color: "white",
                            height: 55,
                          }}
                        >
                          {allPhases.map((val, index) => (
                            <MenuItem key={index} value={val.phase}>
                              Phase {index + 1} &nbsp; : &nbsp; {val.phase}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                      <Typography>Please Select the Subject.</Typography>
                      <Select
                        labelId="subject-label"
                        id="subject"
                        onChange={handleSubjectChange}
                        value={subject || ""}
                      >
                        {allSubjects &&
                          allSubjects.map((item, index) => (
                            <MenuItem key={index} value={item._id}>
                              <Box display={"flex"}>
                                {item.name === "English" && (
                                  <IconLogo
                                    size="20"
                                    image="/assets/Book.png"
                                  />
                                )}{" "}
                                {item.name === "Mathematics" && (
                                  <IconLogo
                                    size="20"
                                    image="/assets/maths.png"
                                  />
                                )}{" "}
                                {item.name === "Science" && (
                                  <IconLogo
                                    size="20"
                                    image="/assets/science.png"
                                  />
                                )}
                                <Typography
                                  sx={{
                                    paddingLeft: 1,
                                  }}
                                >
                                  {item.name}
                                </Typography>
                              </Box>
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12} p={1}>
                  {primaryRole !== "HOD" ? (
                    <Box>
                      <Typography>Please Select the HOD</Typography>{" "}
                      <Grid container spacing={2}>
                        {allHods && allHods.length ? (
                          allHods.map((row, index) => (
                            <Grid key={index} item xs={12} md={4} lg={3}>
                              <Card
                                variant="outlined"
                                sx={{
                                  border: `${
                                    hod == row._id ? "1px solid #3699ff" : ""
                                  }`,
                                  height: "100px",
                                }}
                              >
                                <CardContent
                                  sx={{
                                    padding: "8px",
                                    paddingBottom: "6px !important",
                                  }}
                                  className="hand"
                                >
                                  <Box
                                    display={"flex"}
                                    sx={{ gap: 2 }}
                                    onClick={() => handleHodChange(row)}
                                  >
                                    <Avatar
                                      src={row.image}
                                      sx={{
                                        height: 50,
                                        width: 50,
                                        borderRadius: "11px",
                                      }}
                                    ></Avatar>
                                    <Box>
                                      <Typography variant="body1" fontSize={10}>
                                        <PeopleAltOutlinedIcon
                                          sx={
                                            theme.palette.mode === "dark"
                                              ? {
                                                  color: "#fff",
                                                  fontSize: "14px",
                                                  transform:
                                                    "translate(0px,2px)",
                                                }
                                              : {
                                                  color: "#292D32",
                                                  fontSize: "14px",
                                                  transform:
                                                    "translate(0px,2px)",
                                                }
                                          }
                                        />{" "}
                                        {row.empNo}
                                      </Typography>
                                      <Tooltip title={row.name} placement="top">
                                        <Typography
                                          variant="body1"
                                          fontSize={16}
                                          fontWeight={500}
                                        >
                                          {row.name.length > 15
                                            ? `${row.name.substring(0, 15)}...`
                                            : row.name}
                                        </Typography>
                                      </Tooltip>
                                      <Box display={"flex"} flexWrap={"wrap"}>
                                        {row.departments
                                          .slice(0, 2)
                                          .map((data, index) => {
                                            return data.session &&
                                              data.curriculum ? (
                                              <Chip
                                                key={index}
                                                size="small"
                                                variant="filled"
                                                label={`${data.session} / ${data.curriculum}`}
                                                sx={
                                                  data.session === "Morning"
                                                    ? {
                                                        borderRadius: "0",
                                                        backgroundColor:
                                                          "#6D60E91A",
                                                        color: "#796DEB",
                                                        fontSize: "12px",
                                                        width: 120,
                                                      }
                                                    : {
                                                        borderRadius: "0",
                                                        backgroundColor:
                                                          "#0A57EA1A",
                                                        color: "#0A57EA",
                                                        fontSize: "12px",
                                                        width: 120,
                                                      }
                                                }
                                              />
                                            ) : null;
                                          })}
                                        {row.departments.length > 2 ? (
                                          <Tooltip
                                            title={
                                              <Typography>
                                                {row.departments.map(
                                                  (data, index) => (
                                                    <div
                                                      key={index}
                                                    >{`${data.session} / ${data.curriculum}`}</div>
                                                  )
                                                )}
                                              </Typography>
                                            }
                                          >
                                            <Chip
                                              key={3}
                                              size="small"
                                              variant="filled"
                                              label={`+ ${
                                                row.departments.length - 2
                                              }`}
                                              sx={{
                                                backgroundColor: "#0A57EA1A",
                                                color: "#0A57EA",
                                                fontSize: "12px",
                                              }}
                                            />
                                          </Tooltip>
                                        ) : (
                                          ""
                                        )}
                                      </Box>
                                    </Box>
                                  </Box>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))
                        ) : (
                          <Grid item xs={12} md={3}>
                            <Typography
                              variant="body1"
                              fontSize={16}
                              fontWeight={500}
                            >
                              No Hods Found
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      <br />
                    </Box>
                  ) : (
                    ""
                  )}
                  {/* ---------------------------------------- */}
                  <Grid container>
                    <Grid item xs={12} sx={{ display: "flex" }}>
                      <Typography
                        fontSize={16}
                        fontWeight={600}
                        sx={{ transform: "translate(0px,5px)" }}
                      >
                        {activeRole === "HOD"
                          ? userData.name + "'s "
                          : allHods
                              .filter((data) => data._id === hod)
                              .map((data) => data.name + "'s ")}
                        <span style={{ fontWeight: "300" }}>
                          Team of Teachers. ({filteredTeachers.length}
                          )&nbsp;&nbsp;
                        </span>
                      </Typography>
                      {/* </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{
                        marginTop: {
                          xs: 2,
                          lg: 0,
                        },
                        ml: 2,
                      }}
                    > */}
                      <TextField
                        size="small"
                        id="Team_of_Teachers"
                        placeholder="Search Teacher's Name.."
                        variant="outlined"
                        onChange={handleInputChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  {/* ------------------------------------------- */}
                  <TeacherList
                    teachers={filteredTeachers}
                    class={classes}
                    subject_id={subject}
                    subjectIds={prioritySubjectIds}
                    phase={phase ? phase : phaseData}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default IndexPage;
