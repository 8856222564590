import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

function ProgressTable({ progressData }) {
  const teacher = useSelector((state) => state.teacher);

  return (
    <Box>
      <Box p={2} sx={{ backgroundColor: "#3699FF1A" }}>
        Progress Percentages
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ border: "1px solid #EEEEF2", width: 120, height: 75 }}
              >
                Teacher Name
              </TableCell>
              <TableCell
                sx={{ border: "1px solid #EEEEF2", width: 100, height: 75 }}
              >
                Class
              </TableCell>
              <TableCell sx={{ border: "1px solid #EEEEF2", height: 75 }}>
                % of students made expected Progress
              </TableCell>
              <TableCell sx={{ border: "1px solid #EEEEF2", height: 75 }}>
                % of students made better Progress
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {progressData
              ? progressData.map((item, index) => {
                  return (
                    <TableRow>
                      {index === 0 && (
                        <TableCell
                          rowSpan={progressData.length}
                          sx={{
                            border: "1px solid #EEEEF2",
                            verticalAlign: "top",
                            textAlign: "center",
                          }}
                        >
                          {teacher.name}
                        </TableCell>
                      )}
                      <TableCell sx={{ border: "1px solid #EEEEF2" }}>
                        {item.class + " " + item.batch}
                      </TableCell>
                      <TableCell sx={{ border: "1px solid #EEEEF2" }}>
                        {item.expectedProgress}
                      </TableCell>
                      <TableCell sx={{ border: "1px solid #EEEEF2" }}>
                        {item.betterProgress}
                      </TableCell>
                    </TableRow>
                  );
                })
              : ""}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default ProgressTable;
