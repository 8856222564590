import { BrowserRouter, Outlet } from "react-router-dom";
import { useMemo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import AppRoutes from "routes";
import "./index.css";
import SimpleBackdrop from "components/backdrop";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import CloseIcon from "@mui/icons-material/Close";
import DuplicateWindow from "components/DuplicateWindow";
function App() {
  
  function loadPreventDuplicate() {
    try {
      let self = this;
      $(document).ready(function () {
        if (window.IsDuplicate()) {
          // if (self.authService.isLogged()) {
          toast.error("Duplicate Window");
          // }

          // window.location.href='/duplicate'
          // window.open("about:blank", "_self");
          // window.close();
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

   const CloseButton = ({ closeToast }) => (
     <CloseIcon
       onClick={closeToast}
       style={{ transform: "translate(0px,10px)" }}
     />
   );
  
  // loadPreventDuplicate();
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const [isDuplicate, setIsDuplicate] = useState(false);

  // useEffect(() => {
  //   // Check if a key exists in localStorage indicating an existing instance
  //   const existingInstanceKey = 'csdashBoardv6001';

  //   if (localStorage.getItem(existingInstanceKey)) {
  //     setIsDuplicate(true);
  //   } else {
  //     // Set the key to indicate the current instance
  //     localStorage.setItem(existingInstanceKey, 'true');

  //     // Cleanup when the component unmounts
  //     return () => {
  //       localStorage.removeItem(existingInstanceKey);
  //     };
  //   }
  // }, []); // Only run this effect once, when the component mounts

  // if (isDuplicate) {
  //   return <DuplicateWindow/>;
  // }
  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SimpleBackdrop />
          <ToastContainer autoClose={1000} closeButton={CloseButton} />
          <AppRoutes />
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
