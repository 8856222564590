import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  popoverClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useRef } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CloseIcon from "@mui/icons-material/Close";
// import { BarChart } from "@mui/x-charts";
import { Chart } from "react-google-charts";
import { useEffect, useState } from "react";
import ApiService from "services/apiService2";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { data } from "jquery";
import GroupsIcon from "@mui/icons-material/Groups";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import ApiService2 from "services/apiService2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { toast } from "react-toastify";
import CommonService from "services/commonService";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip);

//mui chart custom tooltip
// const CustomItemTooltipContent = (props) => {
//   const { itemData, series } = props;
//   // console.log(series.custom.AddData);

//   return (
//     <Paper
//       sx={{
//         padding: 4,
//         pl: 2,
//         pt: 1,
//         border: "1px solid #3699FF",
//         boxShadow: "0px 0px 4px ",
//       }}
//     >
//       <Typography>
//         {series.custom.AddData[itemData.dataIndex].observer_name}
//       </Typography>
//       <Typography>
//         {`(${moment(series.custom.AddData[itemData.dataIndex].date)
//           .utc()
//           .format("L")})`}
//       </Typography>
//       <Box display={"flex"} flexDirection={"column"} gap={1} mt={1}>
//         <Chip
//           sx={{
//             borderRadius: "0",
//             backgroundColor: "#3699FF1A",
//             justifyContent: "left",
//             width: "70px",
//           }}
//           icon={
//             <GroupsIcon fontSize="small" sx={{ color: "#00A3FF !important" }} />
//           }
//           label={`${
//             series.custom.AddData[itemData.dataIndex].class_name +
//             " " +
//             series.custom.AddData[itemData.dataIndex].batch_name
//           }`}
//         />
//         <Chip
//           sx={{
//             borderRadius: "0",
//             backgroundColor: "#3699FF1A",
//             justifyContent: "left",
//           }}
//           icon={
//             <MenuBookIcon
//               fontSize="small"
//               sx={{ color: "#00A3FF !important" }}
//             />
//           }
//           label={`${series.custom.AddData[itemData.dataIndex].subject_name}`}
//         />
//         <Box display={"flex"} gap={1}>
//           <Chip
//             sx={{
//               borderRadius: "0",
//               backgroundColor: "#3699FF1A",
//               justifyContent: "left",
//             }}
//             icon={
//               <MilitaryTechIcon
//                 fontSize="small"
//                 sx={{ color: "#00A3FF !important" }}
//               />
//             }
//             label={"Points"}
//           />
//           <Typography
//             variant="caption"
//             sx={{ transform: "translate(0px,7px)" }}
//           >{`: ${
//             series.custom.AddData[itemData.dataIndex].evaluation
//               .total_percentage
//           }`}</Typography>
//         </Box>
//       </Box>
//     </Paper>
//   );
// };

const TeacherLessonObservation = ({
  setOpenLessonOb,
  teacherId,
  ratingarr,
  graph,
  Cindicator,
}) => {
  const isNonMobileScreen = useMediaQuery("(min-width:900px)");
  const theme = useTheme();
  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const chartRef = useRef(null);
  const [chartBase64, setChartBase64] = useState(null);

  const barData = {
    labels: graph.dates && graph.dates.map((rw) => rw.observer_name),
    datasets: [
      {
        data: ratingarr,
        backgroundColor: (context) => {
          const gradient = context.chart.ctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(0, "rgba(54, 153, 255, 0.4)");
          gradient.addColorStop(1, "rgba(54, 153, 255, 1)");
          return gradient;
        },
        borderWidth: 1,
        borderRadius: 10,
        custom: {
          AddData: graph.dates,
        },
      },
    ],
  };

  const barOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "white",
        bodyColor: "black",
        borderColor: "#3699FF",
        borderWidth: 1,
        padding: 15,
        callbacks: {
          label: function (context) {
            const series = context.dataset.custom.AddData[context.dataIndex];
            return [
              `${series.observer_name}`,
              `${moment(series.date).utc().format("L")}`,
              `${series.class_name} ${series.batch_name}`,
              `${series.subject_name}`,
              `${Math.round(series.evaluation.total_percentage)}`,
            ];
          },
          title: function (context) {
            return "";
          },
        },
      },
    },
    scales: {
      x: {
        type: "category",
        offset: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  useEffect(() => {
    setTimeout(() => {
      exportChart();
    }, 1000);
  }, []);

  const exportChart = async () => {
    const link = document.createElement("a");

    if (ratingarr) {
      let base64Data = chartRef.current.toBase64Image();
      setChartBase64(base64Data);
      link.click();
    } else {
      console.error("failed to convert");
    }
  };

  function convertToArray() {
    let analysis_array = [];
    let _dates = graph.dates;
    let _criterias = graph.criterias;
    let _analysis = graph.analysis;
    let indicatorKeys = Object.keys(_analysis);
    let analysis_items = {};
    let _date_array = [];
    let _date_strs = [];
    let indicators = [];
    for (let i = 0; i < indicatorKeys.length; i++) {
      try {
        let key = indicatorKeys[i];
        let obj = {
          key: key,
        };
        let short_key = shortenKey(obj);
        obj.short_key = short_key;
        indicators.push(obj);
        let item = [];
        item.push(short_key);
        if (!analysis_items[short_key]) {
          analysis_items[short_key] = {};
        }
        for (let d = 0; d < _dates.length; d++) {
          try {
            let val = "";
            let dateFormat = new Date(_dates[d]["date"]);
            let ret = getDateString(dateFormat, true, true);
            let date_MMDDYY = getDateString(dateFormat, true, false);
            let _date_key = ret + "_" + d;
            if (_date_strs.indexOf(_date_key) === -1) {
              _dates[d]["_date_key"] = _date_key;
              _dates[d]["short_code"] = "S" + (d + 1);
              _dates[d]["date_string"] = date_MMDDYY;
              _date_array.push(_dates[d]);
              _date_strs.push(ret + "_" + d);
            }
            if (_analysis[key][_dates[d]["date"]]) {
              analysis_items[short_key][_date_key] =
                _analysis[key][_dates[d]["date"]];
            }
            item.push(val);
          } catch (e2) {
            console.log(e2);
          }
        }
        analysis_array.push(item);
      } catch (e1) {
        console.log(e1);
      }
    }
    return {
      analysis_items: analysis_items,
      _date_array: _date_array,
      _criterias: _criterias,
      indicators: indicators,
      charts: {
        bar: chartBase64,
      },
      _header: graph.header,
    };
  }

  function shortenKey(doc) {
    try {
      let max_length = 10;
      let real_name = doc.key;
      let split_names = real_name.split(" ");
      let new_name = split_names[0],
        first_chars = "";
      if (split_names && split_names.length) {
        let first_name = split_names[0];
        new_name = first_name.substring(0, max_length);
        for (let f = 0; f < split_names.length; f++) {
          if (f) {
            let not_first_name = split_names[f];
            if (not_first_name && not_first_name.length) {
              let newString = not_first_name[0].replace("(", "");
              newString = newString.replace(")", "");
              first_chars = first_chars + newString.toUpperCase();
            }
          }
        }
      }
      new_name = new_name + " " + first_chars;
      return new_name;
    } catch (e) {
      return doc.name;
    }
  }

  function getDateString(dateFormat, isForGraph, forPdf = false) {
    let HYPEN = "-",
      COLON = ":",
      SPACE = " ";
    let YYYY = dateFormat.getFullYear();
    let MM = dateFormat.getMonth() + 1;
    let DD = dateFormat.getDate();
    let HH = dateFormat.getHours();
    let MINS = dateFormat.getMinutes();
    let SS = dateFormat.getSeconds();
    let str =
      YYYY + HYPEN + MM + HYPEN + DD + SPACE + HH + COLON + MINS + COLON + SS;
    if (isForGraph) {
      str = DD + HYPEN + MM + HYPEN + YYYY;
      if (forPdf) {
        str = DD + HYPEN + MM;
      }
    }
    return str;
  }

  let paramsData = convertToArray();

  const loPdfDownload = async (selectedYear) => {
    toast.success("Downloading PDF");
    CommonService.loaderON(dispatch);
    try {
      const req = {
        school_id: userData.school_id,
        academic_year: userData.academic_year,
        teacher_id: Cindicator.teacher_id,
        FILE_UPLOAD_URL: "https://teamsqa4000.educore.guru",
        eval_type: Cindicator.type,
        params: paramsData,
      };

      const apiResult = await ApiService2.post(
        "learning-observation/analysis/pdf",
        req
      );
      const data = apiResult.details;
      const base64Data = data.replace(/^data:application\/pdf;base64,/, "");
      const binaryData = atob(base64Data);
      const uint8Array = new Uint8Array(binaryData.length);

      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }

      const pdfData = new Blob([uint8Array], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfData);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "LessonObservationReport.pdf";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      CommonService.loaderOFF(dispatch);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Box height={"100vh"}>
      <Box>
        <Box p={{ xs: 2, md: 4 }} display={"flex"}>
          <Typography fontSize={24} fontWeight={600}>
            Lesson Observation
          </Typography>

          <IconButton
            onClick={() => {
              setOpenLessonOb(false);
            }}
            sx={{ ml: "auto" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Grid container p={{ xs: 2, md: 4 }}>
          <Grid item xs={12} md={6}>
            <Typography fontSize={15} sx={{ color: "#7A7A7A" }}>
              Lesson Observation / Learning Walk Analysis
            </Typography>
            <Typography variant="h5" fontWeight={600}>
              {graph.header ? graph.header.teacher.name : ""}
            </Typography>
            <Chip
              sx={{ borderRadius: "0" }}
              size="small"
              label={graph.header ? graph.header.teacher.employee_no : ""}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            gap={1}
            display={{ xs: "block", md: "flex" }}
            justifyContent={{ sx: "start", md: "right" }}
          >
            <Typography>No. of Submissions</Typography>
            <Chip
              sx={{
                borderRadius: "0",
                border: "1px solid #3699FF",
                color: "#3699FF",
              }}
              size="small"
              variant="outlined"
              label={graph ? graph.dates.length : 0}
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            {ratingarr && ratingarr.length ? (
              //mui chart
              // <BarChart
              //   sx={{ width: { xs: 500, md: 900 } }}
              //   height={isNonMobileScreen ? 500 : 300}
              //   tooltip={{
              //     trigger: "item",
              //     itemContent: CustomItemTooltipContent,
              //   }}
              //   series={[
              //     {
              //       data: ratingarr,
              //       color: "#3699FF",
              //       custom: {
              //         AddData: graph.dates,
              //       },
              //     },
              //   ]}
              //   xAxis={[
              //     {
              //       data:
              //         graph.dates &&
              //         graph.dates.map(
              //           (rw) =>
              //             Math.round(rw.evaluation.total_percentage) +
              //             "\n " +
              //             rw.observer_name
              //           // .length > 10
              //           // ? `${rw.observer_name.substring(0, 6)}...`
              //           // : rw.observer_name)
              //         ),
              //       scaleType: "band",
              //     },
              //   ]}
              // />

              //chart js
              <Bar data={barData} options={barOptions} ref={chartRef}></Bar>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} mt={3}>
            <Typography fontSize={12} color={"#787878"}>
              * The bar chart is displayed based on actual point received by
              teacher
            </Typography>
          </Grid>
        </Grid>
        <Box p={{ xs: 2, md: 4 }}>
          <TableContainer>
            <Table>
              <TableHead>
                {graph.criterias && graph.criterias.length ? (
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: 12,
                        fontWeight: "bold",
                        border: "1px solid #EEEEF2",
                      }}
                    >
                      #Sl No
                    </TableCell>
                    <TableCell
                      sx={
                        theme.palette.mode === "dark"
                          ? {
                              fontSize: 12,
                              fontWeight: "bold",
                              position: "sticky",
                              left: 0,
                              zIndex: "9999 !important",
                              background: "#1f1f1f",
                              boxShadow: "5px 2px 5px grey",
                              border: "1px solid #EEEEF2",
                            }
                          : {
                              fontSize: 12,
                              fontWeight: "bold",
                              position: "sticky",
                              left: 0,
                              zIndex: "9999 !important",
                              background: "white",
                              boxShadow: "5px 2px 5px grey",
                              border: "1px solid #EEEEF2",
                            }
                      }
                    >
                      Lesson Observation Indicators
                    </TableCell>
                    {graph.dates
                      ? graph.dates.map((row, index) => (
                          <TableCell
                            key={index + 2}
                            sx={{
                              fontSize: 12,
                              fontWeight: "bold",
                              border: "1px solid #EEEEF2",
                            }}
                          >
                            {row.observer_name} <br />
                            {moment(row.date_format).utc().format("L")}
                          </TableCell>
                        ))
                      : ""}
                  </TableRow>
                ) : (
                  <></>
                )}
              </TableHead>
              <TableBody>
                {graph.criterias &&
                  graph.criterias.map((cri, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ border: "1px solid #EEEEF2" }}>
                        {index + 1}
                      </TableCell>
                      <TableCell
                        sx={
                          theme.palette.mode === "dark"
                            ? {
                                fontSize: 12,
                                fontWeight: "bold",
                                position: "sticky",
                                left: 0,
                                zIndex: "9999 !important",
                                background: "#1f1f1f",
                                boxShadow: "5px 2px 5px grey",
                                border: "1px solid #EEEEF2",
                              }
                            : {
                                fontSize: 12,
                                fontWeight: "bold",
                                position: "sticky",
                                left: 0,
                                zIndex: "9999 !important",
                                background: "white",
                                boxShadow: "5px 2px 5px grey",
                                border: "1px solid #EEEEF2",
                              }
                        }
                      >
                        {cri._id}
                      </TableCell>
                      {graph.dates &&
                        graph.dates.map((dat, ind) => (
                          <TableCell
                            key={ind}
                            sx={{ border: "1px solid #EEEEF2" }}
                          >
                            {graph.analysis &&
                            graph.analysis[cri._id] &&
                            graph.analysis[cri._id][dat.date] &&
                            graph.analysis[cri._id][dat.date].remark ==
                              "Very Good" ? (
                              <Chip
                                size="small"
                                icon={
                                  <FiberManualRecordIcon
                                    fontSize="small"
                                    sx={{ color: "#008000 !important" }}
                                  />
                                }
                                sx={{
                                  ml: "auto",
                                  borderRadius: "0",
                                  backgroundColor: "#0080001A",
                                  color: "#008000",
                                }}
                                label={
                                  graph.analysis &&
                                  graph.analysis[cri._id] &&
                                  graph.analysis[cri._id][dat.date]
                                    ? graph.analysis[cri._id][dat.date].remark
                                    : ""
                                }
                              />
                            ) : graph.analysis &&
                              graph.analysis[cri._id] &&
                              graph.analysis[cri._id][dat.date] &&
                              graph.analysis[cri._id][dat.date].remark ==
                                "Good" ? (
                              <Chip
                                size="small"
                                icon={
                                  <FiberManualRecordIcon
                                    fontSize="small"
                                    sx={{ color: "#00A3FF !important" }}
                                  />
                                }
                                sx={{
                                  ml: "auto",
                                  borderRadius: "0",
                                  backgroundColor: "#00A3FF1A",
                                  color: "#00A3FF",
                                }}
                                label="Good"
                              />
                            ) : graph.analysis &&
                              graph.analysis[cri._id] &&
                              graph.analysis[cri._id][dat.date] &&
                              graph.analysis[cri._id][dat.date].remark ==
                                "Acceptable" ? (
                              <Chip
                                size="small"
                                icon={
                                  <FiberManualRecordIcon
                                    fontSize="small"
                                    sx={{ color: "#FFA500 !important" }}
                                  />
                                }
                                sx={{
                                  ml: "auto",
                                  borderRadius: "0",
                                  backgroundColor: "#FFA5001A",
                                  color: "#FFA500",
                                }}
                                label="Acceptable"
                              />
                            ) : graph.analysis &&
                              graph.analysis[cri._id] &&
                              graph.analysis[cri._id][dat.date] &&
                              graph.analysis[cri._id][dat.date].remark ==
                                "Outstanding" ? (
                              <Chip
                                size="small"
                                icon={
                                  <FiberManualRecordIcon
                                    fontSize="small"
                                    sx={{ color: "#0000FF !important" }}
                                  />
                                }
                                sx={{
                                  ml: "auto",
                                  borderRadius: "0",
                                  backgroundColor: "#0000FF1A",
                                  color: "#0000FF",
                                }}
                                label="Outstanding"
                              />
                            ) : graph.analysis &&
                              graph.analysis[cri._id] &&
                              graph.analysis[cri._id][dat.date] &&
                              graph.analysis[cri._id][dat.date].remark ==
                                "Weak" ? (
                              <Chip
                                size="small"
                                icon={
                                  <FiberManualRecordIcon
                                    fontSize="small"
                                    sx={{ color: "#f74937 !important" }}
                                  />
                                }
                                sx={{
                                  ml: "auto",
                                  borderRadius: "0",
                                  backgroundColor: "#ff00001a",
                                  color: "#f74937",
                                }}
                                label="Weak"
                              />
                            ) : (
                              <Chip
                                size="small"
                                icon={
                                  <FiberManualRecordIcon
                                    fontSize="small"
                                    color="#FFA500"
                                  />
                                }
                                sx={{
                                  ml: "auto",
                                  borderRadius: "0",
                                }}
                                label="NA"
                              />
                            )}
                          </TableCell>
                        ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Divider />
        <Box display={"flex"} gap={2} justifyContent={"center"} mt={3} pb={3}>
          <Button
            variant="outlined"
            sx={{
              color: "#787878",
              border: "1px solid #787878",
              textTransform: "capitalize",
              ":hover": { border: "1px solid #787878" },
            }}
            onClick={() => {
              setOpenLessonOb(false);
            }}
          >
            Go back
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#3699FF",
              textTransform: "capitalize",
              color: "#fff",
              ":hover": {
                backgroundColor: "#3699FF",
              },
            }}
            onClick={loPdfDownload}
          >
            Export as PDF
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default TeacherLessonObservation;
