import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Tab,
  Tabs,
  Typography,
  Grid,
  ListItemButton,
  Modal,
  Tooltip,
  useTheme,
  TablePagination
} from "@mui/material";
import React, {useState} from "react";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AddIcon from "@mui/icons-material/Add";
import state, { setTeacherClass } from "state";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import _ from "underscore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const TeacherList = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const SubjectData = useSelector((state) => state.subjectId);
  const theme = useTheme();
  const [itemsPerPage, setitemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [passengersCount, setPassengersCount] = useState(props.teachers.length);

  let idd = props.subjectIds.some((item) => props.subject_id === item);
  const teacherView = (id, teacherData, tab = "") => {
    toast.success(teacherData.name + " is selected");
    // console.log(teacherData, "teacherdata");
    dispatch(
      setTeacherClass({
        classes: props.class,
        teacher: teacherData,
      })
    );
    // console.log(idd);
    navigate("/teacher/view/" + props.subject_id + "/" + id + "?tab=" + tab);
  };

  function makeuniq(arr = [], selected_sub = "") {
    let uniqArr = [];
    try {
      for (let index = 0; index < arr.length; index++) {
        try {
          let element = arr[index];
          let ex = _.find(uniqArr, function (one) {
            return (
              one.session_id == element.session_id &&
              one.curriculum_id == element.curriculum_id
            );
          });
          if (!ex) {
            if (element && element.subjects) {
              if (element.subjects.indexOf(selected_sub) !== -1) {
                uniqArr.push(element);
              }
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
      return uniqArr;
    } catch (e) {
      console.log(e);
      return uniqArr;
    }
  }

  const dataarray = props.teachers && props.teachers ? props.teachers : [];

  _.each(dataarray, function (teacher) {
    teacher.new_details = makeuniq(teacher.details, SubjectData);
  });

  const handlePageChange = (event, newPage) => {
   setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setitemsPerPage(parseInt(event.target.value, 10))
    setCurrentPage(0);
  };

  
  return (
    <Box>
      <Grid container spacing={1} mt={2}>
        {props.teachers && props.teachers ? (
          props.teachers.map((row, ind) => (
            <Grid item key={ind} xs={12} md={6} lg={3}>
              <Card
                variant="outlined"
                sx={{ borderRadius: "10px", height: "392px", paddingBottom: 0 }}
              >
                <CardContent
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    padding: "0 !important",
                  }}
                >
                  <Box padding={"16px"}>
                    <Box height={80}>
                      <Box display={"flex"} sx={{ gap: 2 }}>
                        <Avatar
                          src={row.image}
                          sx={{
                            height: 50,
                            width: 50,
                            borderRadius: "11.2px",
                          }}
                        ></Avatar>
                        <Box>
                          <Typography variant="body1" fontSize={10}>
                            <PeopleAltOutlinedIcon
                              sx={
                                theme.palette.mode === "dark"
                                  ? {
                                      color: "#fff",
                                      fontSize: "14px",
                                      transform: "translate(0px,2px)",
                                    }
                                  : {
                                      color: "#292D32",
                                      fontSize: "14px",
                                      transform: "translate(0px,2px)",
                                    }
                              }
                            />{" "}
                            {row.empNo}
                          </Typography>
                          <Typography
                            variant="h5"
                            fontWeight={500}
                            title={row.name}
                          >
                            {row.name && row.name.length > 15
                              ? `${row.name.substring(0, 15)}...`
                              : row.name}
                          </Typography>
                          <Box display={"flex"} flexWrap={"wrap"}>
                            {row.new_details.slice(0, 2).map((data, index) => {
                              return data.session && data.curriculum ? (
                                <Chip
                                  key={index}
                                  size="small"
                                  variant="filled"
                                  label={`${data.session} / ${data.curriculum}`}
                                  sx={
                                    data.session === "Morning"
                                      ? {
                                          borderRadius: "0",
                                          backgroundColor: "#6D60E91A",
                                          color: "#796DEB",
                                          fontSize: "12px",
                                          width: 120,
                                        }
                                      : {
                                          borderRadius: "0",
                                          backgroundColor: "#0A57EA1A",
                                          color: "#0A57EA",
                                          fontSize: "12px",
                                          width: 120,
                                        }
                                  }
                                />
                              ) : null;
                            })}
                            {row.new_details.length > 2 ? (
                              <Tooltip
                                title={
                                  <Typography>
                                    {row.new_details.map((data, inde) => (
                                      <div
                                        key={inde}
                                      >{`${data.session} / ${data.curriculum}`}</div>
                                    ))}
                                  </Typography>
                                }
                              >
                                <Chip
                                  key={3}
                                  size="small"
                                  variant="filled"
                                  label={`+ ${row.new_details.length - 2}`}
                                  sx={{
                                    backgroundColor: "#0A57EA1A",
                                    color: "#0A57EA",
                                    fontSize: "12px",
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              ""
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Divider sx={{ mt: 1 }} />
                    <Box>
                      <Typography mt={1}>Recent Lesson Observations</Typography>
                      {row.lo && row.lo.length > 0
                        ? row.lo.slice(0, 3).map((val, index) => {
                            const str2 =
                              val.updated_grade && val.updated_grade
                                ? val.updated_grade.charAt(0).toUpperCase() +
                                  val.updated_grade.slice(1)
                                : val.evaluation.total_grade
                                    .charAt(0)
                                    .toUpperCase() +
                                  val.evaluation.total_grade.slice(1);
                            return (
                              <ListItemButton
                                sx={{
                                  border: "1px solid #D9D9D9",
                                  borderRadius: "7px",
                                  mt: 1,
                                  height: 50,
                                }}
                                key={index}
                              >
                                <Typography fontSize={12} width={100}>
                                  {moment(val.date_of_observation)
                                    .utc()
                                    .format("DD-MM-YYYY (dddd)")}
                                </Typography>
                                {str2 == "Very good" || str2 == "Very Good" ? (
                                  <Chip
                                    size="small"
                                    icon={
                                      <FiberManualRecordIcon
                                        fontSize="small"
                                        sx={{
                                          color: "#008000 !important",
                                        }}
                                      />
                                    }
                                    sx={{
                                      ml: "auto",
                                      borderRadius: "0",
                                      backgroundColor: "#0080001A",
                                      color: "#008000",
                                      textTransform: "capitalize",
                                      width: 100,
                                      fontSize: 11,
                                    }}
                                    label={str2}
                                  />
                                ) : str2 == "Good" || str2 == "good" ? (
                                  <Chip
                                    size="small"
                                    icon={
                                      <FiberManualRecordIcon
                                        fontSize="small"
                                        sx={{
                                          color: "#00A3FF !important",
                                        }}
                                      />
                                    }
                                    sx={{
                                      ml: "auto",
                                      borderRadius: "0",
                                      backgroundColor: "#00A3FF1A",
                                      color: "#00A3FF",
                                      width: 100,
                                      fontSize: 11,
                                    }}
                                    label={str2}
                                  />
                                ) : str2 == "Acceptable" ||
                                  str2 == "acceptable" ? (
                                  <Chip
                                    size="small"
                                    icon={
                                      <FiberManualRecordIcon
                                        fontSize="small"
                                        sx={{
                                          color: "#FFA500 !important",
                                        }}
                                      />
                                    }
                                    sx={{
                                      ml: "auto",
                                      borderRadius: "0",
                                      backgroundColor: "#FFA5001A",
                                      color: "#FFA500",
                                      width: 100,
                                      fontSize: 11,
                                    }}
                                    label={str2}
                                  />
                                ) : str2 == "Outstanding" ||
                                  str2 == "outstanding" ? (
                                  <Chip
                                    size="small"
                                    icon={
                                      <FiberManualRecordIcon
                                        fontSize="small"
                                        sx={{
                                          color: "#0000FF !important",
                                        }}
                                      />
                                    }
                                    sx={{
                                      ml: "auto",
                                      borderRadius: "0",
                                      backgroundColor: "#0000FF1A",
                                      color: "#0000FF",
                                      width: 100,
                                      fontSize: 11,
                                    }}
                                    label={str2}
                                  />
                                ) : str2 == "Weak" || str2 == "weak" ? (
                                  <Chip
                                    size="small"
                                    icon={
                                      <FiberManualRecordIcon
                                        fontSize="small"
                                        sx={{
                                          color: "#f74937 !important",
                                        }}
                                      />
                                    }
                                    sx={{
                                      ml: "auto",
                                      borderRadius: "0",
                                      backgroundColor: "#ff00001a",
                                      color: "#f74937",
                                      width: 100,
                                      fontSize: 11,
                                    }}
                                    label={str2}
                                  />
                                ) : (
                                  <Chip
                                    size="small"
                                    icon={
                                      <FiberManualRecordIcon
                                        fontSize="small"
                                        color="#FFA500"
                                      />
                                    }
                                    sx={{
                                      ml: "auto",
                                      borderRadius: "0",
                                      width: 100,
                                      fontSize: 11,
                                    }}
                                    label="NA"
                                  />
                                )}
                              </ListItemButton>
                            );
                          })
                        : ""}
                      {row.lo.length == 0 ? (
                        <Box
                          bgcolor={"#F5F5F5"}
                          height={180}
                          mt={1}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "8px",
                          }}
                        >
                          <img src="/assets/No data 2.png" alt="" />
                          <Typography
                            variant="caption"
                            color={"#7A7A7A"}
                            fontSize={10}
                          >
                            No Observations Found !
                          </Typography>
                        </Box>
                      ) : (
                        ""
                      )}
                    </Box>
                    {row.lo.length > 2 ? (
                      <Box textAlign={"center"} fontSize={12}>
                        <Button
                          onClick={() => teacherView(row._id, row, "obser")}
                          variant="text"
                          sx={{
                            textTransform: "capitalize",
                            color: "#787878",
                            transform: "translate(0px,10px)",
                          }}
                        >
                          <AddIcon fontSize="25px" /> View More
                        </Button>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                  <Box textAlign={"center"} mt={"auto"}>
                    <Button
                      onClick={() => teacherView(row._id, row)}
                      sx={{
                        width: "100%",
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        textTransform: "capitalize",
                        color: "#fff",
                        backgroundColor: "#3699FF",
                        ":hover": {
                          backgroundColor: "#3699FF",
                        },
                      }}
                      variant="contained"
                    >
                      {props.phase == "KG"
                        ? "Lesson Observation"
                        : idd
                        ? "Class Story"
                        : "Lesson Observation"}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body1" fontSize={16} fontWeight={500}>
            No Teachers Found
          </Typography>
        )}
        {/* <TablePagination
        component="div"
        onPageChange={handlePageChange}
        page={currentPage}
        count={props.teachers.length}
        rowsPerPage={itemsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      </Grid>
    </Box>
  );
};

export default TeacherList;
